<template>
  <b-table
    :items="certificates"
    :fields="fields"
    :filter="filter"
    :filterIncludedFields="['hosts', 'cn', 'issuer', 'alternative_names', 'filename']"
    sort-icon-left
    responsive
    hover
    :sticky-header="isTableToggled"
    >
    <template
      v-slot:thead-top="data"
      v-if="certificates.length > 10"
      >
      <b-tr class="searchable">
        <b-th :colspan="data.columns">
          <b-form inline>
            <b-button @click="toggleTable" class="mr-4"><b-icon :icon="buttonToggleTable.icon"></b-icon> {{ buttonToggleTable.label }}</b-button>
            <b-input-group>
              <b-input-group-prepend is-text><b-icon icon="filter"></b-icon></b-input-group-prepend>
              <b-input
                placeholder="Certificat..."
                v-model="filter"
                />
            </b-input-group>
          </b-form>
        </b-th>
      </b-tr>
    </template>

     <template v-slot:cell(hosts)="data">
       <div v-for="(h, i) in data.value" :key="i">
         <server-link
           :server="h"
           :project_id="$route.params.project_id"/>
       </div>
     </template>

     <!-- <template v-slot:cell(alternative_names)="data">
       <extendable-link
         v-if="data.item.alternative_names"
         :short_value="data.item.short_alternative_names.join(', ')"
         :long_value="data.item.alternative_names.join(', ')"
         :link="'et ' + (data.item.alternative_names.length - data.item.short_alternative_names.length ) + ' de plus'"
         />
     </template> -->

     <template v-slot:cell(expires)="data">
       <b-badge
         :variant="data.item.class_name"
         :label="getExpireLabel(data.item.expires_days)"
         :title="data.item.raw_expires"
         >{{data.value}}</b-badge>
     </template>

     <template v-slot:cell(actions)="data">
        <b-button variant="info" size="sm" @click="data.toggleDetails">
          Voir {{ data.detailsShowing ? 'moins' : 'plus' }}
        </b-button>
      </template>

     <template v-slot:row-details="data">
       <div>
       <div>
         <b>Filename:</b> {{data.item.filename}}
       </div>
         <b>Alternative names:</b> <extendable-link
         v-if="data.item.alternative_names"
         :short_value="data.item.short_alternative_names.join(', ')"
         :long_value="data.item.alternative_names.join(', ')"
         :link="'et ' + (data.item.alternative_names.length - data.item.short_alternative_names.length ) + ' de plus'"
         />
       </div>
     </template>

  </b-table>
</template>

<script>
import ServerLink from '../misc/ServerLink.vue'
import ExtendableLink from '../misc/ExtendableLink.vue'

export default {
  props: {
    certificates: Array,
  },
  components: {
    ExtendableLink,
    ServerLink,
  },
  methods: {
    toggleTable() {
      if (this.isTableToggled) {
        this.isTableToggled = false
      } else {
        this.isTableToggled = '450px'
      }
    },
    getExpireLabel (days) {
      if (days > 0) {
        return `Expire dans ${days} jours`
      }
      return "Expiré"
    },
  },
  data () {
    return {
      filter: null,
      isTableToggled: '450px',
      fields: [
        {
          key: "hosts",
          label: "Serveurs",
          sortable: true,
        },
        {
          key: "cn",
          label: "CN",
          sortable: true,
        },
        {
          key: "issuer",
          label: "Issuer",
          sortable: true,
        },
        // {
        //   key: "alternative_names",
        //   label: "Noms alternatifs"
        // },
        // {
        //   key: "filename",
        //   label: "Fichier"
        // },
        {
          key: "expires",
          label: "Expire",
          sortable: true,
        },
        {
          key: "actions",
          label: "Details"
        },
      ]
    }
  },
  computed: {
    buttonToggleTable () {
      if (this.isTableToggled) {
        return {label: 'Déplier', icon: 'chevron-down'}
      } else {
        return {label: 'Replier', icon: 'chevron-up'}
      }
    },
  },
  mounted () {

  }
}
</script>
