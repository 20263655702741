<template>
  <div id="clientpanel-body" class="clientpanel-body">
    <div id="clientpanel-navbar" class="main-nav">
      <b-nav vertical>
        <template v-if="$route.params.server_id ? ($parent.project ? $parent.project.identifier : false) : false">
          <b-nav-item class="has-icon" :to="{ name: 'Project', params: { project_id: $parent.project.identifier } }">
            <b-icon icon="chevron-left"></b-icon>
            Projet
          </b-nav-item>
          <template v-if="$parent.project ? $parent.project.identifier : false">
            <b-nav-item class="has-icon" v-b-toggle.nav-servers>
              <div class="d-flex justify-between">
                <div class="collapse-label">
                  <b-icon icon="terminal"></b-icon>
                  Serveurs
                  <b-badge pill variant="secondary">{{ serverCount }}</b-badge>
                </div>
                <b-icon class="d-block" icon="chevron-down"></b-icon>
              </div>
            </b-nav-item>
            <b-collapse id="nav-servers" :visible="isServerRoute">
              <b-nav-item
                v-for="(h, i) in $parent.project.hosts"
                :key="i"
                :to="{ name: 'ProjectServer', params: { project_id: $parent.project.identifier, server_id: h.name } }"
                >
                {{ h.name }}
              </b-nav-item>
            </b-collapse>
          </template>
        </template>

        <b-nav-item
          v-for="(htitle, i) in htitles"
          :key="i"
          :href="$route.path + '#' + htitle.uid"
          :disabled="htitle.hover ? 'disabled' : null"
          :class="{'has-icon':htitle.icon}"
          :id="'nav-'+htitle.uid"
          >
          <b-icon
            v-if="htitle.icon"
            :icon="htitle.icon"
            ></b-icon>
          <span :class="{'fw-bold':htitle.status && htitle.status.level != 'none'}">{{ htitle.short_label || htitle.label }}</span>
          <b-badge v-if="htitle.status && htitle.status.nb > 0" :variant="htitle.status.level">{{ htitle.status.nb }}</b-badge>
          <b-tooltip v-if="htitle.status && htitle.status.msg" :target="'nav-'+htitle.uid" placement="bottom">
            {{ htitle.status.msg }}
          </b-tooltip>
        </b-nav-item>

        <staff-menu />
      </b-nav>
    </div>
    <div  class="container main-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import StaffMenu from './StaffMenu.vue'

export default {
  components: {
    StaffMenu,
  },
  data () {
    return {
      htitles: [],
    }
  },
  mounted() {
    // console.log("route name", this.$route.name)
  },
  computed: {
    isServerRoute () {
      return this.$route.name == "ProjectServer"
    },
    serverCount () {
      return this.$parent.project.hosts.length
    }
  },
  methods: {
    registerHTitle (htitle) {
      this.htitles.push(htitle)
      return this.htitles.length
    },
  },
}

</script>

<style lang="scss">


</style>
