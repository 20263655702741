<template>
  <h-titles>
    <h1 class="title h2">Utilisateurs</h1>
    <b-card no-body>
      <b-table
      hover
      :items="users"
      :fields="fields"
      :filter="filter"
      :filterIncludedFields="['username', 'firstname', 'lastname']"
      >
      <template v-slot:thead-top="data">
        <b-tr>
          <b-th :colspan="data.columns">
            <b-input
            placeholder="Rechercher un utilisateur..."
            v-model="filter"
            />
          </b-th>
        </b-tr>
      </template>

      <template v-slot:cell(is_staff)="data">
        <b-badge
        variant="light"
        :href="'/accounts/switch/?username=' + data.item.username"
        >Switch</b-badge>
      </template>
    </b-table>
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'

export default {
  components: {
    HTitles,
  },
  data () {
    return {
      filter: null,
      fields: [
        {key: "firstname", label: "Prénom"},
        {key: "lastname", label: "Nom"},
        {key: "username", label: "Username"},
        {key: "mail", label: "E-mail"},
        {key: "is_staff", label: "Switch"},
      ],
      users: [],
    }
  },
  mounted() {
    this.$store.commit('setLoading', true)
    this.$store.commit("setTitle", "Changer d'utilisateur")
    this.$http.get('users').then((result) => {
          this.users = result.data.users
          this.$store.commit('setLoading', false)
      });
  },
  methods: {
  },
}

</script>
