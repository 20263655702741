<template>
  <div class="servers-table">
    <b-table
      sort-icon-left
      responsive
      hover
      foot-clone
      :items="servers"
      :fields="servers.length ? getFields: []"
      :filter="filter"
      :filterIncludedFields="['name']"
      :sticky-header="isTableToggled"
      >

      <template
        v-slot:thead-top="data"
        v-if="servers.length > 10"
        >
        <b-tr class="searchable">
          <b-th :colspan="data.columns">
            <b-form inline>
              <b-button @click="toggleTable" class="mr-4"><b-icon :icon="buttonToggleTable.icon"></b-icon> {{ buttonToggleTable.label }}</b-button>
              <b-input-group>
                <b-input-group-prepend is-text><b-icon icon="filter"></b-icon></b-input-group-prepend>
                <b-input
                  placeholder="Nom de serveur..."
                  v-model="filter"
                  />
              </b-input-group>
            </b-form>
          </b-th>
        </b-tr>
      </template>

      <template v-slot:cell(name)="data">
        <server-link
          :server="data.value"
          :project_id="data.item.project_id || $route.params.project_id"
          :infos="data.item"
          />

        <div class="text-small">{{ data.item.description }}</div>
      </template>

      <template v-slot:cell(debian_version)="data">
        <span v-if="data.value">{{ data.value }}</span>
      </template>

      <template v-slot:cell(hosting)="data">
           {{ data.value || "Bearstech"}}
      </template>

      <template v-slot:cell(disk_add)="data">
          <div v-for="(disk,i) in data.value" :key="i">
            {{ disk }}
          </div>
      </template>

      <template v-slot:cell(criticity)="data">
          <b-badge
           :variant="data.value == 'production' ? 'primary' : 'secondary'"
           v-if="data.value"
           >{{ data.value }}</b-badge>
      </template>

      <template v-slot:cell(parsed_ticket)="data">
        <div v-for="(ticket, i) in data.value" :key="i">
          <b-badge
            variant="light"
            v-if="ticket.url"
            :title="ticket.url"
            :href="ticket.url">{{ticket.label}}</b-badge>
        </div>
      </template>

      <template v-slot:cell(parsed_contact)="data">
        <div v-for="(contact, i) in data.value" :key="i">
          <b-badge
            variant="light"
            v-if="contact.url"
            :title="contact.title"
            :href="contact.url">{{contact.label}}</b-badge>
          <b-badge
            variant="light"
            v-else
            >{{contact.label}}</b-badge>
        </div>
      </template>

      <template v-slot:foot(name)="data">
        <b>
          Total
        </b>
      </template>

      <template v-slot:foot(cpu)="data">
        <b>
          {{ sums.cpu }}
        </b>
      </template>

      <template v-slot:foot(ram)="data">
        <b>
          {{ sums.ram }}G
        </b>
      </template>

      <template v-slot:foot(disk)="data">
        <b>
          {{ sums.disk }}G
        </b>
      </template>

      <template v-slot:foot(disk_add)="data">
        <b>
          {{ sums.disk_add }}G
        </b>
      </template>

      <template v-slot:foot(filers)="data">
        <b>
          {{ sums.filers }}G
        </b>
      </template>

      <template v-slot:foot()="data">
        <b></b>
      </template>

    </b-table>
  </div>
</template>

<script>
import ServerLink from '../misc/ServerLink.vue'

export default {
  components: {
    ServerLink,
  },
  props: {
    servers: Array,
  },
  data () {
    return {
      filter: null,
      sums: {cpu: 0, ram: 0, disk: 0, disk_add: 0, filers: 0},
      isTableToggled: '450px'
    }
  },
  mounted () {
    if (this.sums.cpu === 0 && this.servers.length) {
      this.getSums(this.servers)
    }
  },
  watch: {
    "servers": function(newValue) {
      // watch servers and calculate new sums
      if (this.sums.cpu === 0) {
        this.getSums(newValue)
      }
    },
  },
  methods: {
    toggleTable() {
      if (this.isTableToggled) {
        this.isTableToggled = false
      } else {
        this.isTableToggled = '450px'
      }
    },

    normalizeCapacity(data) {
      var amount = 0;
      if (data) {
          data = data.replace(/\s.*$/, '');
          amount = data.replace(/[^0-9]/, '');
          amount = parseInt(amount, 10);
          if (/G$/.exec(data)) {
              amount = amount * 1024;
          } else if (/TB$/.exec(data)) {
              amount = amount * 1024 * 1024;
          }
      }
      return parseInt(amount / 1024, 10);
    },
    getSums(servers) {
      var filers = {}
      for (var s=0; s<servers.length; s++) {
          var i = 0, f = null, size = 0,
              data = servers[s]
          if (data.cpu) {
              this.sums.cpu += parseInt(data.cpu, 10);
          }
          if (data.filers) {
              for (i=0; i<data.filers.length; i++) {
                  f = data.filers[i];
                  if (!filers[f.name]) {
                    size = this.normalizeCapacity(f.size);
                    this.sums.filers += size;
                    filers[f.name] = true;
                  }
              }
          }
          this.sums.ram += this.normalizeCapacity(data.ram);
          this.sums.disk += this.normalizeCapacity(data.disk);
          for (let diskAddSize of data.disk_add) {
            this.sums.disk_add += this.normalizeCapacity(diskAddSize)
          }
      }
    },
  },
  computed: {
    buttonToggleTable () {
      if (this.isTableToggled) {
        return {label: 'Déplier', icon: 'chevron-down'}
      } else {
        return {label: 'Replier', icon: 'chevron-up'}
      }
    },

    getFields () {
      var fields = [
        {key: "name", label: "Serveur", thClass: "col-sm-3", sortable: true},
        {key: "debian_version", label: "Debian", thClass: "col-sm-1", sortable: true},
        {key: "cpu", label: "CPU", thClass: "col-sm-1", sortable: true},
        {key: "ram", label: "RAM", thClass: "col-sm-1", sortable: true},
        {key: "disk", label: "Disk", thClass: "col-sm-1", sortable: true},
        {key: "disk_add", label: "Disk add.", thClass: "col-sm-2", sortable: true},
        {key: "criticity", label: "Criticité", thClass: "col-sm-1", sortable: true},
      ]
      if (this.$store.state.user.is_staff) {
        fields = fields.concat([
          {key: "hosting", label: "Hébergement", thClass: "col-sm-1", sortable: true},
          {key: "parsed_ticket", label: "Ticket", thClass: "col-sm-1",},
          {key: "creation_date", label: "Création", thClass: "col-sm-2", sortable: true},
          {key: "parsed_contact", label: "Contact", thClass: "col-sm-1",},
        ])
      }
      return fields
    },
  },
}
</script>
