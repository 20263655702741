<template>
  <div v-if="!$store.state.loading">
    <div v-if="(context.hosts && context.hosts.length) || false">
      <b-card
        class="mb-4"
        v-if="(context.projects && context.projects.length) || false"
        >
          <b-card-title>
            <h-title label="Projets" uid="projects" :isCardTitle="true" icon="collection"></h-title>
          </b-card-title>

          <b-table
            :items="context.projects"
            :fields="projects_fields"
            :filter="project_filter"
            :filterIncludedFields="['name']"
            responsive
            borderless
            hover
            small
            >
              <template
              v-slot:thead-top="data"
              v-if="context.projects.length > 10"
              >
              <b-tr class="searchable">
                <b-th :colspan="data.columns">
                  <b-input
                  placeholder="Rechercher un projet..."
                  v-model="project_filter"
                  />
                </b-th>
              </b-tr>
            </template>

            <template v-slot:cell(name)="data">
              <router-link :to="{ name: 'Project', params: { project_id: data.item.identifier } }">{{ data.value }}</router-link>
            </template>
          </b-table>
      </b-card>

      <b-card
        class="mb-4"
        no-body
        v-if="(context.hosts && context.hosts.length) || false"
        >
        <b-card-body>
          <b-card-title class="mb-0">
            <h-title label="Serveurs" uid="servers" :isCardTitle="true" icon="terminal"></h-title>
          </b-card-title>
        </b-card-body>
        <servers-table :servers="context.hosts" />
      </b-card>

      <b-card
        class="mb-4"
        no-body
        v-if="(context.certificates && context.certificates.length) || false"
        >
        <b-card-body>
          <b-card-title class="mb-0">
            <h-title label="Certificats" uid="certificates" :isCardTitle="true" icon="award" :status="certificatesStatus"></h-title>
          </b-card-title>
        </b-card-body>
        <certificates-table :certificates="context.certificates" />
      </b-card>

      <b-card
        class="mb-4"
        no-body
        v-if="(upgrades && upgrades.length) || false"
        >
        <b-card-body>
          <b-card-title class="mb-0">
            <h-title
              label="Mise à jour de paquets sur une année"
              short_label="Mise à jour"
              uid="upgrades"
              :isCardTitle="true" icon="arrow-clockwise"
              ></h-title>
          </b-card-title>
        </b-card-body>
        <upgrades-graph :upgrades="upgrades" />
        <a
          :href="'/api' + $route.path + '/packages_upgrades.csv'"
          title="Export CSV de mise à jour de paquets Debian"
          style="position: absolute; right: 10em; top: 5em;"
          >
          <b-icon icon="download"></b-icon>
        </a>
      </b-card>

      <b-card
        class="mb-4"
        no-body
        >
        <b-card-body>
          <b-card-title class="mb-0">
            <h-title
              label="Empreinte carbone"
              short_label="Empreinte carbone"
              uid="carbone"
              :isCardTitle="true" icon="thermometer"
              ></h-title>
          </b-card-title>
        </b-card-body>
        <div class="carbone-title">
          <div class="col-sm-5 float-left">
            <h4>Impact carbone de votre infrastructure</h4>
            <h4 class="co2-per-month"><strong>{{ co2sum }} kg CO2e / mois ({{ moisAnnee  }})</strong></h4>
          </div>
          <div id="carbon-little-graph">
            <b-icon
                class="border rounded-circle"
                icon="question-circle"
                id="popover-target-1"
            />

            <b-popover target="popover-target-1" triggers="click" placement="left">
              <template #title><strong>Ce que comprend le bilan</strong></template>
Le calcul de l'empreinte carbone du fonctionnement de vos serveurs prend en compte les paramètres suivants : 
<br/><br/>
<strong>FABRICATION</strong> : le bilan de construction et de logistique lié aux équipements est calculé en fonction de la taille de votre infrastructure. Bearstech exploite ces équipements au moins 10 ans : chaque année un dixième de ce coût CO2 est attribué au bilan.
<br/><br/>
<strong>ÉLECTRICITÉ</strong> : les équipements consomment de l'électricité; leur équivalent CO2 est évalué en fonction des sources d'énergies exploitées par les datacenters concernés. Les consommations indirectes sont intégrées à ce calcul.
<br/><br/>
<strong>OPÉRATIONS</strong> : inclut les émissions liées aux opérations de Bearstech. Ce chapitre comprend :<br/>
- Les dépenses énergétiques du fonctionnement de Bearstech (hors datacenter)<br/>
- Les transports, les déplacements professionnels divers<br/>
- Les biens et services achetés pour le fonctionnement de Bearstech
<br/><br/>
La ventilation de ces coûts dépend des dimensions de votre infrastructure chez Bearstech, ainsi que l'usage que vous en faites en terme d'utilisation processeur. 
<br/><br/>
Vous trouverez plus <a href="https://support.bearstech.com/projects/documentation/wiki/Bilan_Carbone" target="_blank">d'informations ici</a> sur les modalités de calcul.
            </b-popover>
          </div>
        </div>
        <carbone-graph :carbone="carbone" />
        <div class="m-4">
          <h5 class="mb-0">Impact carbone par serveur par mois.</h5>
          <span class="small">Unité kg CO2e</span>
        </div>
        <div class="co2-by-server">
          <carbone-servers-table :servers="context.hosts" :carbone="carbone" />
        </div>
      </b-card>

      <common-server-tables :context="context" :alerts="alerts" />

    </div>
    <div v-else>
      <b-alert variant="info" show>
        Aucunes informations à afficher
      </b-alert>
    </div>
  </div>
</template>

<script>
import ServersTable from './ServersTable.vue'
import CarboneServersTable from './CarboneServersTable.vue'
import CertificatesTable from './CertificatesTable.vue'
import CommonServerTables from './CommonServerTables.vue'
import HTitle from '../misc/HTitle.vue'
import UpgradesGraph from '../misc/UpgradesGraph.vue'
import CarboneGraph from '../misc/CarboneGraph.vue'

export default {
  components: {
    CertificatesTable,
    ServersTable,
    CarboneServersTable,
    CommonServerTables,
    HTitle,
    UpgradesGraph,
    CarboneGraph,
  },
  props: {
    context: Object,
    alerts: Object,
    upgrades: Array,
    carbone: Object,
  },
  data () {
    return {
      project_filter: null,
      projects_fields: [
        {
          key: "name",
          label: "Nom"
        }
      ]
    }
  },
  computed: {
    co2sum(){
      let total = 0
      let now = new Date();
      let past = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      let month = ('0'+(past.getMonth()+1)).slice(-2);
      let year = past.getFullYear();
      let lastcheck = year+'-'+month
      let servers = this.context.hosts

      for(let i in servers){
        let servername = (servers[i].hostname) ? servers[i].hostname : servers[i].name.split('.')[0]
        let serverco2 = this.carbone[servername]
        if(serverco2.[lastcheck] != undefined){
          servers[i]['carbone'] = serverco2[lastcheck].toFixed(3)
          total += serverco2[lastcheck]
        }
      }

      return total.toFixed(2)
    },
    moisAnnee(){
      var months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
      let now = new Date();
      let past = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      let month = months[past.getMonth()];
      let year = past.getFullYear();

      return month + ' ' + year
    },
    certificatesStatus () {
      let result = {
        level: 'none',
        nb: 0,
        msg: ""
      }
      let nbExpired = 0
      let nbSoonExpired = 0
      for (let cert of this.context.certificates) {
        if (cert.class_name == 'danger') {
          result.level = 'danger'
          if (cert.expires_days <= 0) {
            nbExpired = nbExpired + 1
          } else {
            nbSoonExpired = nbSoonExpired + 1
          }
        } else if (cert.class_name == 'warning') {
          if (result.level != 'danger') {
            result.level = 'warning'
          }
          nbSoonExpired = nbSoonExpired + 1
        }
      }
      result.nb = nbExpired + nbSoonExpired
      let msgs = []
      if (nbExpired > 0) {
        msgs.push(nbExpired + " certificat(s) expiré(s)")
      }
      if (nbSoonExpired > 0) {
        msgs.push(nbSoonExpired + " certificat(s) bientôt expiré(s)")
      }
      result.msg = msgs.join(' et ')
      return result
    },
  },
  methods: {
  },
  mounted () {

  }
}
</script>

<style lang="scss">
#carbon-little-graph{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 25px;
  cursor: pointer;
}
.popover{
  max-width: 80%;
  min-width: 30%;
}
</style>

