
<template>
  <h-titles>
    <h1 class="title h2">Métriques mensuel</h1>
    <b-card no-body>
      <b-table
      hover
      :items="metrics"
      :fields="fields"
      >
      </b-table>
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'

export default {
  components: {
    HTitles,
  },
  data () {
    return {
      fields: [
        {key: "date", label: "Date"},
        {key: "wordpress_production", label: "Wordpress en production"},
        {key: "drupal_production", label: "Drupal en production"},
      ],
      metrics: [],
    }
  },
  mounted() {
    this.$store.commit('setLoading', true)
    this.$store.commit("setTitle", "Métriques mensuel")
    this.$http.get('metrics').then((result) => {
          this.metrics = result.data.metrics
          this.$store.commit('setLoading', false)
      });
  },
  methods: {
  },
}

</script>

