<template>
  <h-titles>
    <h1 class="title h2">
      Oncalls
    </h1>
    <b-card no-body>
      <alerts-table :alerts="alerts" />
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import AlertsTable from './tables/AlertsTable.vue'

export default {
  components: {
    HTitles,
    AlertsTable,
  },
  data () {
    return {
      alerts: {alerts:[]},
    }
  },
  mounted() {
    this.$store.commit('setTitle', 'Oncalls')
    this.$store.commit('setLoading', true)
    this.$http.get(document.location.pathname.slice(1))
      .then((result) => {
        console.log(result)
        this.alerts = result.data.alerts
        this.$store.commit('setLoading', false)
      })
  },
  methods: {
  },
}

</script>
