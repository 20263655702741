<template>
  <div class="draw"></div>
</template>

<script>
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

export default {
  props: {
    hostname: String,
    period: String,
    y_label: String,
    series: Array,
  },
  data() {
    return {
      graph: null,
      delayed: null,
    }
  },
  watch: {
    "series": "drawPanel",
  },
  mounted() {
    this.$el.style.opacity = 0.3
    this.$el.style.width = this.$el.offsetWidth
    this.$el.style.height = (this.$el.offsetWidth / 3) + 'px'
    this.graph = echarts.init(this.$el);
    this.drawPanel(this.series)
  },
  methods: {
    formatter(params) {
      var fmt = "dd-MM-yyyy hh:mm"
      let fdate = echarts.format.formatTime(
        fmt, params[0].axisValue
      )
      var tpl = `${fdate}<br/>`
      for (var i = 0; i < params.length; i++) {
        tpl += params[i].marker + params[i].seriesName + ": " + params[i].value[1]
        tpl += "<br/>"
      }
      return tpl
    },
    drawPanel(series) {
      let self = this;
      self.$el.style.opacity = 0.3

      // add attributes to series' items
      for(let serie of series) {
          serie.showSymbol = false,
          serie.type = "bar",
          serie.stack = "stacked"
          serie.label = {
                  show: false,
              },
          serie.connectNulls = false
          serie.lineStyle = {}
      }

      // console.log(dd)
      // specify chart configuration item and data
      self.graph.clear()
      self.graph.setOption({
        color: [
          "#016d79",
          "#d88926",
          // defaults
          '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'
        ],
        title: {show: false},
        toolbox: {show: false},
        legend: {
            show: false,
            type: 'plain',
        },
        tooltip: {
            trigger: 'axis',
            show: true,
            formatter: this.formatter,
        },
        xAxis: {
            type: 'time',
            axisLine: {
                symbol: ['none', 'arrow'],
                symbolOffset: [0, 14]
            }
        },
        yAxis: {},
        series: series,
      });
      self.$el.style.opacity = 1
    }
  }
}
</script>

