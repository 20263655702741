<template>
  <div v-if="project">
    <div id="header" v-if="$route.params.project_id" ref="redmine">
      <!-- redmine widgets -->
      <div id="quick-search" v-html="quick_search"></div>
      <h1 id="redmine_title">{{project.name}}</h1>
      <div id="main-menu" v-html="main_menu"></div>
    </div>
  </div>

</template>

<script>
import jQuery from 'jquery'

export default {
  props: {
      project: {identifier: null, name: ""},
  },
  data () {
    return {
      main_menu: "",
      quick_search: "",
    }
  },
  created () {
    // update data from localStorage
    var project_identifier = this.$route.params.project_id
    if (typeof(Storage) !== "undefined" && project_identifier) {
      console.log("Update widgets from storage")
      if (localStorage[`${project_identifier}#name`]) {
        this.project.name = localStorage[`${project_identifier}#name`]
        this.$store.commit('setTitle', this.project.name)
      }
      if (localStorage[`${project_identifier}#main_menu`]) {
        this.main_menu = localStorage[`${project_identifier}#main_menu`]
      }
      if (localStorage[`${project_identifier}#quick_search`]) {
        this.quick_search = localStorage[`${project_identifier}#quick_search`]
      }
    }
  },
  mounted() {
    var project_identifier = this.$route.params.project_id
    if (!project_identifier) {
      return
    }
    // fetch redmine widgets
    // store results in localStorage for next time
    if (!this.main_menu) {
      this.$http(`redmine/widgets/${project_identifier}/project_main_menu`)
      .then((data) => {
        var e = jQuery(data.data, "#main-menu")
        console.log("main_menu:", e)
        jQuery('a', e).each(function() {
            jQuery(this).attr(
              "href",
              "https://support.bearstech.com" + jQuery(this).attr("href"))
        })
        this.main_menu = e.html()
        if (typeof(Storage) !== "undefined") {
          localStorage[`${project_identifier}#main_menu`] = this.main_menu.trim()
        }
      })
    }
    if (!this.quick_search) {
      this.$http(`redmine/widgets/${project_identifier}/project_search`)
      .then((data) => {
        var e = jQuery(data.data, "#quick-search")
        jQuery("div.drdn-content", e).remove()
        jQuery("form", e).each(function() {
            jQuery(this).attr(
              "action",
              "https://support.bearstech.com" + jQuery(this).attr("action"))
        })
        this.quick_search = e.html()
        if (typeof(Storage) !== "undefined") {
          localStorage[`${project_identifier}#quick_search`] = this.quick_search.trim()
        }
      })
    }
    if (project_identifier) {
      this.$store.commit('setRedmineHeaderOffset', this.$refs.redmine.offsetHeight)
    }
  },
  methods: {
    setRedmineHeaderOffset () {
      if (this.$ref && this.refs.redmine) {
        this.$store.commit('setRedmineHeaderOffset', this.$refs.redmine.offsetHeight)
      }
    }
  },
  watch: {
    $route: 'setRedmineHeaderOffset',
  },
}
</script>

<style lang="scss">
#project_menu {
  float: right;
}
#header {
  position: relative;
  // color: #f8f8f8;
  background-color: #fff;
  min-height: 5em;
  padding: 0px;
}
#quick-search {
  margin: 20px 10px;
  float: right;

  form {
    display: inline;
  }

  #q {
    width: 130px;
    height: 24px;
    box-sizing: border-box;
    vertical-align: middle;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 1px 10px;
    background-color: #fff;
    color: #3d454c;
    font-family: monospace;
    font-size: 12px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

}

#project-jump.drdn {
  width: 200px;
  display: inline-block;
  position: relative;

}
.drdn-trigger {
  width: 100%;
  height: 24px;
  display: inline-block;
  padding: 3px 18px 3px 6px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin: 0 !important;
  vertical-align: middle;
  color: #555;
  background: #fff url("https://support.bearstech.com/images/arrow_down.png") no-repeat 97% 50%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.3;
  font-family: "helvetica neue",helvetica,arial,sans-serif;
  text-rendering: optimizeLegibility;
}

h1#redmine_title {
  font-family: "helvetica neue",helvetica,arial,sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 20px 10px 15px 75px;
  margin: 0px;
  background: url("https://support.bearstech.com/themes/circle-bearstech/images/bearstech-logo.png") no-repeat 20px 9px;
  color: #103f55;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.3;
}

#main-menu {
  left: 0px;
  width: 100%;
  height: 41.2px;
  border-top: 4px solid #5bccba;
  background: linear-gradient(to right,#168dab,#1b1021);
  color: #ddd;
  font-weight: 500;
  position: relative;
  box-sizing: border-box;
  bottom: 0px;
  margin-right: -500px;

  ul {
    position: relative;
    padding: 0 15px;
    width: 100%;
    white-space: nowrap;
    clear: right;
    margin: 0;
    top: -5px;

    li {
      margin: 0px;
      padding: 5px 0px;
      float: none;
      display: inline-block;
      list-style-type: none;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1.3;
      font-family: "helvetica neue",helvetica,arial,sans-serif;
      text-rendering: optimizeLegibility;

      &:last-of-type {
        position: absolute;
        right: 0;
        padding-left: 26px;
        margin-left: 5px;
        margin-right: 40px;
        font-weight: bold;
        font-size: 16px;
        background: url("https://support.bearstech.com/themes/circle-bearstech/images/bearstech-infra.png") no-repeat 0px 4px;

        >a {
          color: #f5cd62;
        }
      }

      a {
        display: block;
        color: #ddd;
        text-decoration: none;
        font-weight: bold;
        margin: 0;
        padding: 4px 10px 4px 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &:hover, &.selected {
          background: none;
          color: #fff;
        }
      }
    }
  }
}
</style>
