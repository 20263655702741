<template>
  <div id="co2-block">
    <div class="draw"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

export default {
  props: {
    carbone: Object,
  },
  data() {
    return {
      graph: null,
      delayed: null,
    }
  },
  mounted() {
    this.$el.style.opacity = 0.3
    this.$el.style.width = this.$el.offsetWidth
    this.$el.style.height = (this.$el.offsetWidth / 3) + 'px'
    this.graph = echarts.init(this.$el);

    this.graph.setOption({
        color: [
          "#016d79",
          "#d88926",
          // defaults
          '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'
        ],
        title: {
            show: false,
        },
        legend: {
            show: false,
            type: 'plain',
        },
        tooltip: {
            trigger: 'axis',
            show: true,
        },
        toolbox: {
            show: false,
        },
        xAxis: {
            type: 'category',
            axisLine: {
                symbol: ['none', 'arrow'],
                symbolOffset: [0, 14]
            }
        },
        yAxis: {
            type: 'value',
            name: "Emissions carbone (en kg CO2e)",
        },
    });
    this.delayedDraw()
  },
  methods: {
    delayedDraw() {
      if (this.delayed) {
        clearTimeout(this.delayed)
      }
      this.delayed = setTimeout(this.drawPanel, this.panel_index * 500)
    },
    drawPanel() {
      let self = this;
      self.$el.style.opacity = 0.3
      const dd = [];

      // Array of array, [mois, value] (value = somme de tous les serveurs du projet pour ce mois)
      // Get the 12 last months
      let now = new Date();
      let months = []
      for (let i = 1; i < 13; i++) {
        let past = new Date(now.getFullYear(), now.getMonth() - i, 1);
        let month = ('0'+(past.getMonth()+1)).slice(-2);
        let year = past.getFullYear();
        months.push(year+'-'+month)
      }

      // Build datas by month, for each server
      let datas = []
      let current = 0
      for (let m in months){
        current = 0
        for(let s in this.carbone){
          let serverCo2 = this.carbone[s]
          if(serverCo2.hasOwnProperty(months[m])){
            current += serverCo2[months[m]]
          }
        }
        datas.push([months[m], current.toFixed(2)])
      }

      let serie = {}
      serie.showSymbol = false,
      serie.type = "bar",
      serie.barWidth = 20
      serie.stack = "stacked"
      serie.label = {
              show: false,
          },
      serie.connectNulls = false
      serie.lineStyle = {}
      serie.data = datas
      dd.push(serie)

      // specify chart configuration item and data
      self.graph.setOption({
          xAxis: {
            type: 'category',
            data: months.reverse()
          },
          series: dd,
      });
      self.$el.style.opacity = 1
    }
  }
}
</script>

