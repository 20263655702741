<template>
  <div class="server-link">
    <router-link
      :to="serverParams"
      class="server-link"
      >{{ server }}</router-link>
        <b-icon
          v-if="server_infos && ($store.state.user.is_staff && !server_infos.in_infra)"
          icon="exclamation-circle-fill"
          variant="danger"
          :title="'Serveur non présent dans infra/ (collecté à ' + server_infos.last_collected + ')'">!</b-icon>
  </div>
</template>

<script>
export default {
  props: {
    server: String,
    project_id: String,
    infos: Object,
  },
  data () {
    return {
      server_infos: this.infos || false
    }
  },
  computed: {
    serverParams () {
      var params = {
        name: "Server",
        params: {
          server_id: this.server,
        }
      }
      if (this.project_id) {
        params.name = 'ProjectServer'
        params.params.project_id = this.project_id
      }
      return params
    }
  },
  mounted () {
    let self = this
    if (this.$store.state.user.is_staff) {
      this.$servers_infos.get(self.server, self.infos || {}).then((infos) => {
        self.server_infos = infos
      })
    }
  },
  updated () {
    let self = this
    if (this.$store.state.user.is_staff) {
      this.$servers_infos.get(self.server, {}).then((infos) => {
        self.server_infos = infos
      })
    }
  }
}
</script>

<style lang="scss">
div.server-link {
  display: inline-block;
  white-space: nowrap;
  .b-icon {
    margin-left: 0.2em;
  }
}
a.server-link {
  display: inline-block;
  white-space: nowrap;
}
</style>
