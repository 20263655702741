<template>
  <span>
    <b-button v-if="!isLink" :href="ticketUrl" :class="{ 'badge': isBadge }" :variant="getVariant">
      <slot>{{text}}</slot>
    </b-button>
    <a :class="css" :href="ticketUrl" v-else><slot>{{text}}</slot></a>
  </span>
</template>

<script>
import {generateTicket} from '../../helpers/ticket.js'
export default {
  name: 'button-support-ticket',
  props: ['text', 'identifier', 'subject', 'body', 'isLink', 'isBadge', 'css', 'variant'],
  computed: {
    ticketUrl () {
      return generateTicket(this.identifier, this.subject, this.body)
    },
    getVariant () {
      if (this.variant) {
        return this.variant
      }
      return 'primary'
    }
  }
}
</script>
