<template>
  <div :style="styleVars">
    <router-view></router-view>
    <div id="footer" class="bordered-top" ref="footer">
      <div class="infos container" v-if="last_updated && !$store.state.loading">
        <span>
          Dernière mise à jour des données: {{ last_updated }}
        </span>

        <b-button
          @click="syncNow()"
          id="sync_now"
          size="sm"
          v-if="$store.state.user.is_staff"
          >Forcer la mise à jour</b-button>
      </div>
    </div>
    <div
      class="white-overlay"
      v-if="$store.state.loading"
      >
      <div class="spinner">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    let scrollY = window.scrollY
    return {
      last_updated: null,
      scrollY: scrollY
    }
  },
  computed: {
    topOffset () {
      let topMenuHeight = document.getElementById('top-menu').offsetHeight
      // let offset = topMenuHeight + this.$store.state.redmineHeaderOffset - this.scrollY
      let offset = 32 - this.scrollY
      if (this.$store.state.redmineHeaderOffset) {
        offset = 134 - this.scrollY
      }
      if (this.$store.state.redmineHeaderOffset > 102) {
        offset = topMenuHeight + this.$store.state.redmineHeaderOffset - this.scrollY
      }
      if (offset < 0) {
        offset = 0
      }
      return offset.toString() + 'px'
    },

    styleVars () {
      return {
        '--top-offset': this.topOffset
      }
    }
  },
  created () {
    var app = this
    app.$store.commit('setTitle', null)
    app.$http.interceptors.response.use(function (response) {
      if (response.status === 200) {
        if (response.data.user && response.data.user.username) {
          app.$store.commit('setUser', response.data.user)
        }
      }
      return response
    })
    window.addEventListener('scroll', this.setScrollY)
  },
  destroyed () {
    window.removeEventListener('scroll', this.setScrollY)
  },
  mounted () {
    if (!window.location.href.match(/accounts/)) {
      this.$http.get('last_updated').then((result) => {
        this.last_updated = result.data.last_updated
      }).catch((error) => {
        console.log(error.toJSON());
      })
    }
  },
  methods: {
    syncNow () {
      this.$store.commit('setLoading', true)
      this.$http.post('last_updated', {}, {timeout: 100000})
      .then(() => {
        window.location.href = window.location.href + ''
      })
      .catch((error) => {
        console.log(error.toJSON());
        window.location.href = window.location.href + ''
        throw error
      })
    },
    setScrollY () {
      this.scrollY = window.scrollY
    },
    setRedmineHeaderOffset () {
      this.$store.commit('setRedmineHeaderOffset', 0)
    }
  },
  watch: {
    $route: 'setRedmineHeaderOffset',
  },
}
</script>

<style lang="scss">
#clientpanel-body {
  position: relative;
  padding-left: 15rem;

  #clientpanel-navbar {
    position: fixed;
    z-index: 1000;
    width: 15rem;
    top: var(--top-offset);
    left: 0;
    bottom: 0;
    overflow-x: auto;
    background-color: #0d3044;

    .b-icon {
      margin-right: 0.5rem;
    }

    .nav-item {
      padding-left: 1.8rem;

      &.has-icon {
        padding-left: 0;
      }
    }

    .collapse {
      li {
        background-color: darken(#0d3044, 5);
      }
    }

    a {
      color: #ccc;

      &:hover, &.active {
        color: #fff;
      }
    }
  }
}

#app {

}

section.projects table thead {
  display: none;
}

// search header
table thead tr.searchable th {
  border: none;
  padding-top: 1em;
  padding-bottom: 1em;
}
tfoot th.b-table-sort-icon-left {
  background-image: none !important;
}
table tfoot .sr-only {
  display: none;
}

.text-small {
  font-size: 80%;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin: 0;
}
.white-overlay .spinner {
  margin-top: 20%;
  padding-left: 48%;
}
#footer span {
  margin: 0px 2em;
}
</style>
