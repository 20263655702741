<template>
  <div class="carbone-servers-table">
    <b-table
      sort-icon-left
      responsive
      hover
      foot-clone
      :items="servers"
      :fields="servers.length ? getFields: []"
      >

      <template v-slot:cell(name)="data">
        <server-link
          :server="data.value"
          :project_id="data.item.project_id || $route.params.project_id"
          :infos="data.item"
          />

        <div class="text-small">{{ data.item.description }}</div>
      </template>

      <template v-slot:cell(carbone)="data">
           {{ data.value ? data.value : 'N/A' }}
      </template>

      <template v-slot:foot(name)="data">
        <b>
          Total
        </b>
      </template>

      <template v-slot:foot(carbone)="data">
        <b>
          {{ carbonesum }}
        </b>
      </template>

    </b-table>
  </div>
</template>

<script>
import ServerLink from '../misc/ServerLink.vue'

export default {
  components: {
    ServerLink,
  },
  props: {
    servers: Array,
    carbone: Object,
  },
  data () {
    let total = 0
    let now = new Date();
    let past = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    let month = ('0'+(past.getMonth()+1)).slice(-2);
    let year = past.getFullYear();
    let lastcheck = year+'-'+month
    for(let i in this.servers){
      let servername = (this.servers[i].hostname) ? this.servers[i].hostname : this.servers[i].name.split('.')[0]
      let serverco2 = this.carbone[servername]
      if(serverco2.[lastcheck] != undefined){
        this.servers[i]['carbone'] = serverco2[lastcheck].toFixed(3)
        total += serverco2[lastcheck]
      }
      else{
        this.servers[i]['carbone'] = 'N/A'
      }
    }
    return {
      carbonesum: total.toFixed(2),
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
  },
  computed: {
    getFields () {
      var fields = [
        {key: "name", label: "Serveur", thClass: "col-sm-7", sortable: true},
        {key: "carbone", label: "Carbone (en kg CO2e)", thClass: "col-sm-3", sortable: true},
      ]
      return fields
    },
  },
}
</script>
