<template>

  <div v-if="$store.state.user.is_staff">
    <b-nav-item class="has-icon" v-b-toggle.nav-staff>
      <div class="d-flex justify-between">
        <div>
          <b-icon icon="person-badge"></b-icon>
          Staff
          <b-badge pill variant="secondary">{{ itemCount }}</b-badge>
        </div>
        <b-icon class="d-block" icon="chevron-down"></b-icon>
      </div>
    </b-nav-item>
    <b-collapse id="nav-staff" :visible="isVisible">
      <b-nav-item
        v-for="(item, i) in items" :key="i"
        :to="item.to"
        >{{ item.label }}
      </b-nav-item>
    </b-collapse>
  </div>


</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      items: [
        {label: "Métriques mensuel", to: {name: "Metrics"}},
        {label: "Oncalls", to: {name: "Alerts"}},
        {label: "Virtual Hosts", to: {name: "Vhosts"}},
        {label: "Certificats", to: {name: "Certificates"}},
        {label: "Serveurs", to: {name: "Servers"}},
        {label: "Projects", to: {name: "Projects"}},
        {label: "Mailing", to: {name: "Mailing"}},
        {label: "Switch user", to: {name: "SwitchUser"}},
      ],
    }
  },
  mounted() {
  },
  computed: {
    isVisible () {
      for (var item of this.items) {
        if (this.$route.name == item.to.name) {
          return true
        }
      }
      return false
    },
    itemCount () {
      return this.items.length
    }
  },
  methods: {
  },
}

</script>
