<template>
  <div class="project">
    <redmine-project :project="project"/>
    <h-titles>
      <common-tables
        :context="project"
        :alerts="alerts"
        :upgrades="upgrades"
        :carbone="carbone"
        />

      <mails-datas :project_id="project.identifier" />

      <uptime-table :context="project"></uptime-table>

    </h-titles>
  </div>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import MailsDatas from './misc/MailsDatas.vue'
import RedmineProject from './misc/RedmineProject.vue'
import CommonTables from './tables/CommonTables.vue'
import UptimeTable from './tables/UptimeTable.vue'

export default {
  name: 'Project',
  components: {
    RedmineProject,
    CommonTables,
    MailsDatas,
    HTitles,
    UptimeTable,
  },
  data () {
    this.$store.commit('setLoading', true)
    return {
      project: {},
      alerts: {alerts:[]},
      upgrades: [],
      carbone: {},
    }
  },
  mounted () {
    this.$store.commit('setLoading', true)
    this.$http.get(document.location.pathname.slice(1).replace(/\/$/, ''))
      .then((result) => {
        this.project = result.data.project
        this.$store.commit('setTitle', this.project.name)
        if (typeof(Storage) !== "undefined") {
          localStorage[`${this.project.identifier}#name`] = this.project.name
        }
        this.alerts = result.data.alerts
        this.upgrades = result.data.upgrades
        this.carbone = result.data.carbone
        console.log(this.carbone)
        if (!this.$route.params.project_id) {
          this.$store.commit("setTitle", this.server.name)
        }
        this.$store.commit('setLoading', false)
      })
      .catch((error) => {
          this.$store.commit('setLoading', false)
          this.$bvModal.msgBoxOk("" + error)
            .then(() => {
                window.location.href = "/"
             })
          throw error
      })
  }
}
</script>
