import Vue from 'vue'
import Router from 'vue-router'

// client user
import Projects from '../components/Projects.vue'
import Project from '../components/Project.vue'
import Servers from '../components/Servers.vue'
import Server from '../components/Server.vue'

// staff user
import Certificates from '../components/Certificates.vue'
import Vhosts from '../components/Vhosts.vue'
import Alerts from '../components/Alerts.vue'
import Mailing from '../components/Mailing.vue'
import SwitchUser from '../components/SwitchUser.vue'
import Metrics from '../components/Metrics.vue'

Vue.use(Router)

export default new Router({
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    { path: '/projects', name: 'Projects', component: Projects },


    { path: '/projects/:project_id', name: 'Project', component: Project },
    { path: '/projects/:project_id/', component: Project },

    { path: '/projects/:project_id/servers/:server_id', name: 'ProjectServer', component: Server },
    { path: '/projects/:project_id/servers/:server_id/', component: Server },

    { path: '/servers', name: 'Servers', component: Servers },

    { path: '/servers/:server_id', name: 'Server', component: Server },
    { path: '/servers/:server_id/', component: Server },

    { path: '/certificates', name: 'Certificates', component: Certificates },
    { path: '/mailing', name: 'Mailing', component: Mailing},
    { path: '/alerts', name: 'Alerts', component: Alerts},
    { path: '/vhosts', name: 'Vhosts', component: Vhosts},
    { path: '/users', name: 'SwitchUser', component: SwitchUser},
    { path: '/metrics', name: 'Metrics', component: Metrics},
  ]
})
