<template>
  <b-table
    :items="connections"
    :fields="fields"
    :filter="filter"
    :filterIncludedFields="['login', 'formatted_duration']"
    sort-icon-left
    responsive
    hover
    :sticky-header="isTableToggled"
    >
    <template
      v-slot:thead-top="data"
      v-if="connections.length > 10"
      >
      <b-tr class="searchable">
        <b-th :colspan="data.columns">
          <b-form inline>
            <b-button @click="toggleTable" class="mr-4"><b-icon :icon="buttonToggleTable.icon"></b-icon> {{ buttonToggleTable.label }}</b-button>
            <b-input-group>
              <b-input-group-prepend is-text><b-icon icon="filter"></b-icon></b-input-group-prepend>
              <b-input
                placeholder="Login/durée..."
                v-model="filter"
                />
            </b-input-group>
          </b-form>
        </b-th>
      </b-tr>
    </template>

    <template v-slot:cell(start)="data">
      {{data.item.formatted_start}}
    </template>

    <template v-slot:cell(end)="data">
      {{data.item.formatted_end}}
    </template>

  </b-table>
</template>

<script>

export default {
  props: {
    connections: Array,
  },
  components: {
  },
  methods: {
    toggleTable() {
      if (this.isTableToggled) {
        this.isTableToggled = false
      } else {
        this.isTableToggled = '450px'
      }
    },

    formatted_date(date) {
      let oDate = new Date(date)
      return oDate.toLocaleString('fr-FR', {})
    },
  },
  computed: {
    buttonToggleTable () {
      if (this.isTableToggled) {
        return {label: 'Déplier', icon: 'chevron-down'}
      } else {
        return {label: 'Replier', icon: 'chevron-up'}
      }
    },
  },
  data () {
    return {
      filter: null,
      isTableToggled: '450px',
      fields: [
        {
          key: "login",
          label: "Login",
        },
        {
          key: "source",
          label: "Source",
        },
        {
          // key: "formatted_start",
          key: "formatted_start",
          label: "Connexion",
        },
        {
          // key: "formatted_end",
          key: "formatted_end",
          label: "Déconnexion",
        },
        {
          key: "formatted_duration",
          label: "Durée",
        },
      ]
    }
  }
}
</script>
