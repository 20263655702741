<template>
  <div class="alerts-table">
    <div>
      <b-table
        responsive
        hover
        :busy="isBusy"
        :items="month_alerts"
        :fields="fields"
        :filter="filter"
        :filterIncludedFields="['host', 'probe', 'group']"
        >

        <template
          v-slot:thead-top="data">
          <b-tr>
            <b-th>
              <b-select
                :options="monthes"
                v-model="selected_month"
                @change="updateAlerts()"
                />
            </b-th>
            <b-th :colspan="data.columns - 1">
                <b-input
                  placeholder="Rechercher un nom de serveur/sonde/groupe..."
                  v-model="filter"
                  v-if="!month_alerts.length > 10"
                  />
            </b-th>
          </b-tr>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template v-slot:cell(host)="data">
          <span v-for="(h, index) in data.value" :key="index">
            <server-link
              :server="h"
              :project_id="$route.params.project_id"/>
              <span v-if="index+1 < data.value.length">, </span>
          </span>
        </template>

      </b-table>
    </div>
    <div v-if="!month_alerts.length && !isBusy">
        <b-alert class="m-4" variant="success" show>
          Aucune alerte pour ce mois
        </b-alert>
    </div>
  </div>
</template>

<script>
import ServerLink from '../misc/ServerLink.vue'

export default {
  components: {
    ServerLink,
  },
  props: {
    alerts: null
  },
  data () {
    var fields = []
    if (!this.$route.params.server_id) {
        fields.push({key: "host", label: "Server",})
    }
    fields = fields.concat([
        {key: "id", label: "Cible"},
        {key: "start", label: "Début"},
        {key: "end", label: "Fin"},
        {key: "duration", label: "Durée"},
        {key: "sonde", label: "Sonde"},
        {key: "group", label: "Groupe"},
        {key: "reason", label: "Raison"}
      ])

    // month / year selector
    var now = new Date(),
        monthes = [],
        month_alerts = this.alerts.alerts,
        monthes_mapping = {
          1: "Janvier",
          2: "Février",
          3: "Mars",
          4: "Avril",
          5: "Mai",
          6: "Juin",
          7: "Juillet",
          8: "Août",
          9: "Septembre",
          10: "Octobre",
          11: "Novembre",
          12: "Décembre",
        }

    var year = now.getFullYear(),
        month = now.getMonth() + 1
    var selected_month = `${year}/${month}`
    while (monthes.length < 12) {
      monthes.push({
        text: `${monthes_mapping[month]} ${year}`,
        value: `${year}/${month}`,
      })
      month--
      if (month == 0) {
        month = 12
        year--
      }
    }

    return {
      isBusy: false,
      filter: null,
      fields: fields,
      monthes: monthes,
      selected_month: selected_month,
      month_alerts: month_alerts,
    }
  },
  watch: {
    alerts () {
      this.month_alerts = this.alerts.alerts
      this.isBusy = false
    },
  },
  methods: {
    updateAlerts () {
      this.isBusy = true
      var url = document.location.pathname.slice(1).replace(/\/$/, '')
      url = url + `/period/${this.selected_month}`
      console.log(url)
      this.$http.get(url).then((result) => {
        this.month_alerts = result.data.alerts.alerts
        this.isBusy = false
      })
    },
  },
}
</script>
