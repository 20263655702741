<template>
  <section
    v-on:mouseenter="hover = true"
    v-on:mouseleave="hover = false"
    :class="uid"
    :id="uid"
    >
    <template v-if="isCardTitle">
      <b-icon v-if="icon" :icon="icon" class="mr-2"></b-icon>
      <span class="h5 mb-0">{{ label }}</span>
      <a class="ml-2" :href="$route.path + '#' + uid" v-if="hover">
        <b-icon
          class="border rounded-circle"
          variant="info"
          icon="hash"
        />
      </a>
    </template>
    <h4 v-else :class="{'mb-0 h5':inCardHeader}">{{ label }} <a
       :href="$route.path + '#' + uid"
       v-if="hover"
        ><b-icon
           class="border rounded-circle"
           variant="info"
           icon="hash"
        /></a></h4>
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    label: String,
    short_label: String,
    uid: String,
    inCardHeader: Boolean,
    isCardTitle: Boolean,
    icon: String,
    isNewFeature: Boolean,
    status: Object,
  },
  data () {
    return {"hover": false, "index": 0}
  },
  created () {
    // find the HTitles component
    var p = this.$parent
    while (!p.registerHTitle) {
      p = p.$parent
    }
    this.index = p.registerHTitle(this)
  },
  methods: {
    waitForScroll() {
      console.log('HTitle.waitForScroll')
      if (!this.$store.state.loading) {
        setTimeout(() => {
          // scroll to element
          console.log('HTitle - Jump to', this.$route.hash)
          this.$el.scrollIntoView()
        }, 500)
      } else {
        setTimeout(this.waitForScroll, 500)
      }
    },
  },
  mounted () {
    if (this.$route.hash == '#' + this.uid) {
      // scroll to my section when the hash is mine
      setTimeout(this.waitForScroll, 500)
    }
  }
}
</script>
