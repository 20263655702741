<template>
  <div class="draw"></div>
</template>

<script>
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

export default {
  props: {
    hostname: String,
    panel: String,
    panel_index: Number,
    period: String,
  },
  data() {
    return {
      graph: null,
      delayed: null,
    }
  },
  mounted() {
    this.$el.style.opacity = 0.3
    this.$el.style.width = this.$parent.$el.offsetWidth
    this.$el.style.height = (this.$parent.$el.offsetWidth / 3) + 'px'
    this.graph = echarts.init(this.$el);

    this.graph.setOption({
        color: [
          "#016d79",
          "#d88926",
          // defaults
          '61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'
        ],
        title: {
            text: this.panel,
            show: true
        },
        legend: {
            show: true,
            type: 'plain',
        },
        tooltip: {
            trigger: 'axis',
            show: true,
            axisPointer: {
              label: {
                  formatter: function(params) {
                      return echarts.format.formatTime(
                          "dd/MM hh:mm",
                          params.value,
                       )
                  }
              },
            },
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                  title: "Télécharger",
                  name: this.hostname + " - " + this.panel,
                }
            }
        },
        xAxis: {
            //name: 'time',
            type: "time",
            axisLine: {
                symbol: ['none', 'arrow'],
                symbolOffset: [0, 14]
            }
        },
        yAxis: {
            type: 'value',
        },
    });
    this.delayedDraw()
  },
  watch: {
    "period": "delayedDraw",
  },
  methods: {
    delayedDraw() {
      if (this.delayed) {
        clearTimeout(this.delayed)
      }
      this.delayed = setTimeout(this.drawPanel, this.panel_index * 500)
    },
    unitSize(value) {
        const units = ['k', 'M', 'G', 'T'].reverse();
        const sign = value < 0 ? '-': '';
        value = Math.abs(value);
        let i = 3 * units.length;
        for (let u of units) {
            if(value >= 10 ** i) {
                return sign + Math.round(value / 10 ** i) + u;
            }
            i -= 3;
        }
        return sign + value;
    },
    tagTemplate(tpl, tags) {
        if(tags === undefined || tpl.indexOf('$') == -1) {
            return tpl;
        }
        const t = /\$tag_([a-zA-Z0-9_]+)/g;
        let buff = "";
        let poz = 0;
        for(let r of tpl.matchAll(t)){
            buff += tpl.substring(poz, r.index);
            poz = r.index + r[0].length;
            buff += tags[r[1]];
        }
        buff += tpl.substring(poz, tpl.length);
        return buff;
    },
    drawPanel() {
      let self = this;
      self.$el.style.opacity = 0.3
      return self.$http.get(`/graphs/${self.hostname}/${self.panel}/${self.period}`)
          .then(data => {
              let r = data.data
              const dd = [];
              let i = 0;
              for(let data of r.datas) {
                  for(let serie of data.results[0].series) {
                      let name;
                      if((i + 1) > r.targets.length) {
                          name = r.targets[0];
                      } else {
                          name = r.targets[i];
                      }
                      if(name == '$tag_host.$col') { // hardcoded FTW
                          name = '';
                      } else {
                          name = self.tagTemplate(name, serie.tags);
                      }
                      dd.push({
                          data: serie.values,
                          showSymbol: false,
                          type: 'line',
                          label: {
                              show: true,
                          },
                          name: name,
                          connectNulls: false,
                          lineStyle: {}
                      });
                      i++;
                  }
              }
              //const format = r.yaxes[0].format;
              let format = 'short';
              switch(self.panel) {
                  case 'CPU':
                      format = 'short';
                      break;
                  case 'Load':
                      format = 'short';
                      break;
                  case 'Memory':
                      format = 'percent';
                      break;
                  case 'Network 1':
                      format = 'Bps';
                      break;
                  case 'Disk IO':
                      format = 'Bps';
                      break;
                  case 'Disk capacity':
                      format = 'percent';
                      break;
                  default:
                      format = 'short';
              }

              // specify chart configuration item and data
              self.graph.setOption({
                  yAxis: {
                      axisLabel: {
                          formatter: function(value) {
                              let unit;
                              switch(format) {
                                  case 'short':
                                      unit = '';
                                      break;
                                  case 'percent':
                                      unit = ' %';
                                      break;
                                  case 'Bps':
                                      unit = 'B/s';
                                      break;
                                  default:
                                      unit = ` ${format}`;
                              }
                              return self.unitSize(value ) + unit;
                          }
                      },
                  },
                  series: dd,
              });
              self.$el.style.opacity = 1
          }).catch(() => {});
    }
  }
}
</script>
