<template>
  <span>
    <span>
      {{ extended ? long_value : short_value }}
    </span>
    <span
      v-if="!extended && short_value.length != long_value.length"
      >
      <b-link
         v-bind:title="long_value"
         v-on:click="toogle()"
         href="#"
         >
         {{ link }}
      </b-link>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    short_value: null,
    long_value: null,
    link: null,
  },
  data: () => {
    return {
      extended: false,
    }
  },
  methods: {
    toogle () {
      this.extended = !this.extended
    }
  },
}
</script>
