<template>
  <h-titles>
    <h1 class="title h2">Projets</h1>
    <b-card no-body>
      <b-table
      sort-icon-left
      responsive
      hover
      :items="projects"
      :fields="fields"
      :filter="filter"
      :filterIncludedFields="['name', 'hosts']"
      >
      <template v-slot:thead-top="data">
        <b-tr class="searchable">
          <b-th :colspan="data.columns">
            <b-input
            placeholder="Rechercher un nom de projet/serveur..."
            v-model="filter"
            />
          </b-th>
        </b-tr>
      </template>

      <template v-slot:cell(identifier)="data">
          <router-link :to="{ name: 'Project', params: { project_id: data.item.identifier } }">{{ data.item.name }}</router-link>
      </template>

      <template v-slot:cell(hosts)="data">
        <div class="row">
          <div class="col-sm-6" v-for="host in data.item.hosts" v-bind:key="host.identifier">
            <server-link
              :server="host.name"
              :project_id="data.item.identifier"
              :infos="host"
              />
          </div>
        </div>
      </template>

    </b-table>
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import ServerLink from './misc/ServerLink.vue'

export default {
  name: 'Projects',
  components: {
    HTitles,
    ServerLink,
  },
  data () {
    this.$store.commit("setTitle", "Projets")
    this.$store.commit("setLoading", true)
    return {
      filter: null,
      projects: [],
      fields: [
        {
          key: "identifier",
          label: "Projets",
          thClass: "col-sm-4",
          sortable: true,
        },
        {
          key: "hosts",
          label: "Serveurs",
        },
      ]
    }
  },
  mounted () {
    this.$http.get('projects')
      .then((result) => {
        console.log(result)
        this.projects = result.data.projects
        this.$store.commit('setLoading', false)
      })
  }
}
</script>

<style lang="scss">
</style>
