<template>
  <div class="draw"></div>
</template>

<script>
import * as echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'

export default {
  props: {
    upgrades: Array,
  },
  data() {
    return {
      graph: null,
      delayed: null,
    }
  },
  mounted() {
    this.$el.style.opacity = 0.3
    this.$el.style.width = this.$el.offsetWidth
    this.$el.style.height = (this.$el.offsetWidth / 3) + 'px'
    this.graph = echarts.init(this.$el);

    this.graph.setOption({
        color: [
          "#016d79",
          "#d88926",
          // defaults
          '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'
        ],
        title: {
            show: false,
        },
        legend: {
            show: false,
            type: 'plain',
        },
        tooltip: {
            trigger: 'axis',
            show: true,
        },
        toolbox: {
            show: false,
        },
        xAxis: {
            type: 'time',
            axisLine: {
                symbol: ['none', 'arrow'],
                symbolOffset: [0, 14]
            }
        },
        yAxis: {
            type: 'value',
            name: "Nombre de paquets mis à jour",
        },
    });
    this.delayedDraw()
  },
  methods: {
    delayedDraw() {
      if (this.delayed) {
        clearTimeout(this.delayed)
      }
      this.delayed = setTimeout(this.drawPanel, this.panel_index * 500)
    },
    drawPanel() {
      let self = this;
      self.$el.style.opacity = 0.3
      const dd = [];
      for(let serie of self.upgrades) {
          serie.showSymbol = false,
          serie.type = "bar",
          serie.barWidth = 5
          serie.stack = "stacked"
          serie.label = {
                  show: false,
              },
          serie.connectNulls = false
          serie.lineStyle = {}
          dd.push(serie)
      }

      console.log(dd)
      // specify chart configuration item and data
      self.graph.setOption({
          yAxis: {},
          series: dd,
      });
      self.$el.style.opacity = 1
    }
  }
}
</script>

