<template>
  <div v-if="!$store.state.loading">

    <b-card
      class="mb-4"
      v-if="(context.backups && context.backups.length) || false"
      no-body
      >
      <b-card-body>
        <b-card-title>
          <h-title label="Politique de rétention de sauvegardes" short_label="Sauvegardes" uid="backups" :isCardTitle="true" icon="clock-history"></h-title>
        </b-card-title>
      </b-card-body>

      <b-table
        id="backups"
        :items="context.backups"
        :fields="backups_fields"
        sort-icon-left
        responsive
        hover
        :sticky-header="isBakcupTableToggled"
        >

        <template
          v-slot:thead-top="data"
          v-if="context.backups.length > 10"
          >
          <b-tr>
            <b-th :colspan="data.columns">
              <b-form inline>
                <b-button @click="toggleBackupTable" class="mr-4"><b-icon :icon="buttonBackupToggleTable.icon"></b-icon> {{ buttonBackupToggleTable.label }}</b-button>
              </b-form>
            </b-th>
          </b-tr>
        </template>

        <template v-slot:cell(host)="data">
          {{data.item.host}}:{{data.item.dirname}}
        </template>

        <template v-slot:cell(keep)="data">
          <span v-for="(ret, k) in data.item.keep" :key="k">
            dernière<span v-if="ret.value > 1">s</span>&nbsp;
            <span v-if="ret.value > 1">{{ret.value}}</span>
            sauvegarde<span v-if="ret.value > 1">s</span>
            {{units_mapping[ret.unit]}}<span v-if="ret.value > 1">s</span>
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mb-4"
      v-if="(context.oncalls && context.oncalls.length) || false"
      no-body
      >
      <b-card-body>
        <b-card-title>
          <h-title label="Monitoring ONCALL" short_label="Monitoring" uid="oncalls" :isCardTitle="true" icon="bell"></h-title>
        </b-card-title>
      </b-card-body>

      <b-table
        :items="context.oncalls"
        :fields="oncalls_fields"
        responsive
        hover
        >

        <template v-slot:cell(items)="data">
          <span v-for="(item, i) in data.value" :key="i">
            <a :href="item">{{ item }}</a><br>
          </span>
        </template>

      </b-table>
    </b-card>

    <b-card
      class="mb-4"
      v-if="(context.filers && context.filers.length) || false"
      no-body
      >
      <b-card-body>
        <b-card-title>
          <h-title label="Filers" uid="filers" :isCardTitle="true" icon="hdd-rack"></h-title>
        </b-card-title>
      </b-card-body>

      <b-table
        :items="context.filers"
        :fields="filers_fields"
        sort-icon-left
        responsive
        hover
        >

        <template v-slot:cell(host)="data">
          <span v-for="(h, i) in data.value" :key="i">
            <server-link :server="h" :project_id="$route.params.project_id"/>
            <span style="margin-left:0.5em"></span>
          </span>
        </template>

      </b-table>
    </b-card>

    <b-card
      class="mb-4"
      v-if="$route.params.server_id && context.virtualhosts || false"
      no-body
      >
      <b-card-body>
        <b-card-title>
          <h-title label="Virtual hosts" uid="virtualhosts" :isCardTitle="true" icon="globe"></h-title>
        </b-card-title>
      </b-card-body>
      <vhosts-table :vhosts="getVhosts(context.virtualhosts)" />
    </b-card>

    <b-card
      class="mb-4"
      no-body
      v-if="$store.state.user.is_staff"
      >
      <b-card-body>
        <b-card-title>
          <h-title label="Dernières alertes" short_label="Alertes" uid="alerts" :isCardTitle="true" icon="exclamation-triangle"></h-title>
        </b-card-title>
      </b-card-body>
      <alerts-table :alerts="alerts" />
    </b-card>

  </div>
</template>

<script>
import AlertsTable from './AlertsTable.vue'
import VhostsTable from './VhostsTable.vue'
import HTitle from '../misc/HTitle.vue'
import ServerLink from '../misc/ServerLink.vue'

export default {
  components: {
    HTitle,
    ServerLink,
    AlertsTable,
    VhostsTable,
  },
  props: {
    context: Object,
    alerts: Object,
  },
  data () {
    return {
      isBakcupTableToggled: '450px',
      units_mapping: {
        "days": "journalière",
        "weeks": "hebdomadaire",
        "months": "mensuelle",
        "years": "annuelle"
      },
      backups_fields: [
        {
          key: "host",
          label: "Chemin",
          sortable: true,
        },
        {
          key: "keep",
          label: "Rétention",
        }
      ],
      oncalls_fields: [
        {
          key: "id",
          label: "Type",
        },
        {
          key: "items",
          label: "Valeurs testées",
        }
      ],
      filers_fields: [
        {
          key: "size",
          label: "Taille",
        },
        {
          key: "host",
          label: "Monté sur",
        }
      ]
    }
  },
  computed: {
    buttonBackupToggleTable () {
      if (this.isBakcupTableToggled) {
        return {label: 'Déplier', icon: 'chevron-down'}
      } else {
        return {label: 'Replier', icon: 'chevron-up'}
      }
    },
  },
  methods: {
    toggleBackupTable() {
      if (this.isBakcupTableToggled) {
        this.isBakcupTableToggled = false
      } else {
        this.isBakcupTableToggled = '450px'
      }
    },
    getVhosts (vhosts) {
      var result = [],
          k = null
      for (k in vhosts) {
        result.push(vhosts[k])
      }
      return result
    },
  },
}
</script>
