<template>
  <h-titles>
    <h1 class="title h2">Serveurs</h1>
    <b-card no-body>
      <servers-table :servers="servers" />
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import ServersTable from './tables/ServersTable.vue'

export default {
  name: 'Servers',
  components: {
    HTitles,
    ServersTable,
  },
  data () {
    this.$store.commit("setTitle", "Serveurs")
    this.$store.commit('setLoading', true)
    return {
      servers: [],
    }
  },
  mounted () {
      this.$http.get(`servers`)
        .then((result) => {
          console.log(result)
          this.total = result.data.total_count
          this.servers = result.data.hosts
          this.$store.commit('setLoading', false)
        })
        .catch((error) => {
            this.$store.commit('setLoading', false)
            this.$bvModal.msgBoxOk("" + error)
              .then(() => {
                  window.location.href = "/"
               })
            throw error
        })
  }
}
</script>
