<template>
  <div>
    <div v-if="!$store.state.loading">
      <div v-if="(context.hosts && context.hosts.length) || false">
        <b-card
          class="mb-4"
          no-body
          >
          <b-card-body>
            <b-card-title class="mb-0 d-flex justify-content-between">
              <h-title
                label="Monitoring Uptime"
                short_label="Uptime"
                uid="uptimes"
                :isCardTitle="true" icon="graph-down"
                ></h-title>
                <div>
                  <b-btn class="mr-2" variant="light" size="sm" @click="getChecks()" title="Rafraîchir"><b-icon icon="arrow-clockwise"></b-icon></b-btn>
                  <b-btn v-if="settings" :disabled="loadingSubscribe" variant="light" size="sm" @click="setSubscribe()" :title="[!settings.contact_in_team ? 'S\'abonner aux alertes' : 'Se désabonner des alertes']">
                    <b-icon v-if="!settings.contact_in_team" icon="bell"></b-icon>
                    <b-icon v-else icon="bell-fill"></b-icon>
                  </b-btn>
                </div>
            </b-card-title>
          </b-card-body>

          <b-table v-if="checks.length" hover :items="checks" :fields="chekcksFields" :busy="busy">
            <template v-slot:cell(status)="data">
                <b-badge variant="success" v-if="data.item.status == 'up'">UP</b-badge>
                <b-badge v-else-if="data.item.status == 'unknown'">UNKNOWN</b-badge>
                <b-badge variant="danger" v-else>DOWN</b-badge>
            </template>

            <template v-slot:cell(actions)="data">
              <div class="row"
                  v-if="$store.state.user.is_staff"
                  >
                  <div class="col">
                    <b-button variant="danger" size="sm"
                      @click="deleteCheckModalInit(data.item)" title="Supprimer"
                      ><b-icon icon="trash"></b-icon></b-button>
                  </div>
                  <div class="col">
                    <b-button
                      :href="'https://my.pingdom.com/app/reports/uptime#check=' + data.item.id"
                      target="_new"
                      variant="info" size="sm" title="Voir ce check sur https://my.pingdom.com/">
                      <b-icon icon="folder-symlink"></b-icon>
                    </b-button>
                  </div>
              </div>
            </template>
          </b-table>
          <div v-else class="mx-4">
            Aucun site monitoré
          </div>

          <b-modal id="modal-check-delete" v-model="deleteCheckModalShow" title="Supprimer un check">
            <div v-if="checkToDelete">
              Confirmer la suppression du check <span class="text-danger">{{ checkToDelete.name }}</span> ?
            </div>

            <template #modal-footer="{ ok, cancel}">
              <b-button variant="danger" @click="deleteCheckSubmit()">
                Supprimer
              </b-button>
              <b-button @click="deleteCheckModalShow = false">
                Annuler
              </b-button>
            </template>
          </b-modal>

          <div v-if="$store.state.user.is_staff">
            <b-button class="mb-3 ml-3" v-b-toggle.not-monitored-collapse>Afficher/cacher sites non monitorés</b-button>
            <b-button class="mb-3 ml-3" variant="primary" @click="createCheckModalInit(null)">Ajouter un site non répertorié</b-button>

            <b-collapse id="not-monitored-collapse">
              <b-table v-if="ready" hover :items="sites" :fields="sitesFields" :busy="busy">
                <template v-slot:cell(actions)="data">
                  <b-button variant="primary" size="sm" @click="createCheckModalInit(data.item)">Créer un check</b-button>
                </template>
              </b-table>

              <b-modal id="modal-check-create" v-model="createCheckModalShow" title="Créer un nouveau check">
                <b-form v-if="createCheckSite" inline>
                  <b-form-select
                    id="select-https"
                    class="mr-2"
                    v-model="createCheckSite.encryption"
                    :options="[{ text: 'HTTPS://', value: true }, { text: 'HTTP://', value: false }]"
                  ></b-form-select>
                  <b-form-input
                    id="input-hostname"
                    v-model="createCheckSite.host"
                    placeholder="Saisir une URL"
                    required
                  ></b-form-input>
                </b-form>

                <template #modal-footer="{ ok, cancel}">
                  <b-button variant="primary" @click="createCheckSubmit()">
                    Créer
                  </b-button>
                  <b-button @click="createCheckModalShow = false">
                    Annuler
                  </b-button>
                </template>
              </b-modal>
            </b-collapse>
          </div>

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import HTitle from '../misc/HTitle.vue'

export default {
  name: 'UptimeTable',

  components: {
    HTitle,
  },

  props: {
    context: Object,
  },

  data () {
    return {
      ready: false, // True après récupération des checks pingdom
      busy: false, // True pendant la récupération des données
      chekcksFields: [
        {key: "hostname", label: "URL",},
        {key: "status", label: "Statut",},
        {key: "actions", label: "Actions",},
      ],
      checks: [],
      sitesFields: [
        {key: "url", label: "URL",},
        {key: "actions", label: "Actions",},
      ],
      createCheckModalShow: false,
      createCheckSite: {
        encryption: true, // SSL
        host: "",
        name: "",
        type: 'http',
        tags: null,
        teamids: null,
      },
      checkToDelete: null,
      deleteCheckModalShow: false,
      settings: null,
      loadingSubscribe: false,
    }
  },

  computed: {
    sites () {
      // console.log('project', this.context)
      let sites = []
      if (this.context.hosts) {
        let virtualhosts = {}
        for (const host of this.context.hosts) {
          if (host.virtualhosts) {
            virtualhosts = {...virtualhosts, ...host.virtualhosts}
          }
        }
        for (const [key, value] of Object.entries(virtualhosts)) {
          let inSites = false
          for (const site of sites) {
            if (site.url == value.servername) {
              inSites = true
            }
          }
          for (const check of this.checks) {
            if (check.hostname == value.servername) {
              inSites = true
            }
          }
          if (!inSites) {
            sites.push({vhost: key, url: value.servername})
          }
        }
      }
      // console.log('sites', sites)
      sites.sort((a, b) => {
        let splittedA = a.url.split('.')
        let splittedB = b.url.split('.')
        let domainA = splittedA.reverse()[1]
        let domainB = splittedB.reverse()[1]
        if (domainA < domainB)
          return -1
        if ( domainA > domainB)
          return 1
        return 0
      })
      return sites
    },
  },

  methods: {
    async getChecks () {
      // console.log('getChecks',this.context)
      if (this.context.identifier) {
        this.busy = true
        this.$http.get(`/projects/${this.context.identifier}/pingdom/checks/`)
          .then(response => {
            console.log(response.status)
            this.checks = response.data.checks
            this.ready = true
            this.busy = false
          })
          .catch(error => {
            console.log(error)
            throw error
          })
      }
    },

    async getSettings () {
      // console.log('Settings')
      this.$http.get(`/projects/${this.context.identifier}/pingdom/settings/`)
        .then(response => {
          console.log(response.status)
          this.settings = response.data
        })
        .catch(error => {
          console.log(error)
          throw error
        })
    },

    createCheckModalInit (site) {
      // console.log(site)
      if (site) {
        this.createCheckSite = {
          encryption: true, // SSL
          host: site.url,
          name: site.url,
          type: 'http',
          tags: [this.context.identifier],
          teamids: this.settings.team.id,
          resolution: 1, // ping every mn
          sendnotificationwhendown: 3, // alert after 3 down (3mn)
        }
      } else {
        this.createCheckSite = {
          encryption: true, // SSL
          host: '',
          name: '',
          type: 'http',
          tags: [this.context.identifier],
          teamids: this.settings.team.id,
          resolution: 1, // ping every mn
          sendnotificationwhendown: 3, // alert after 3 down (3mn)
        }
      }
      this.createCheckModalShow = true
    },

    createCheckSubmit () {
      // console.log(this.createCheckSite)
      if (this.$store.state.user.is_staff) {
        this.createCheckSite.name = this.createCheckSite.host
        this.$http.post(`/projects/${this.context.identifier}/pingdom/checks`, this.createCheckSite)
          .then(pingdomResponse => {
            console.log(pingdomResponse.status)
            this.getChecks()
            this.createCheckModalShow = false
          })
          .catch(error => {
            console.log(error)
            throw error
          })
      }
    },

    deleteCheckModalInit(check) {
      // console.log(check)
      this.checkToDelete = check
      this.deleteCheckModalShow = true
    },

    deleteCheckSubmit () {
      // console.log('Suppression du check', this.checkToDelete)
      if (this.$store.state.user.is_staff) {
        this.$http.delete(`/projects/${this.context.identifier}/pingdom/checks`, { data: { delcheckids: this.checkToDelete.id }})
          .then(pingdomResponse => {
            console.log(pingdomResponse.status)
            this.getChecks()
            this.deleteCheckModalShow = false
          })
          .catch(error => {
            console.log(error)
            throw error
          })
      }
    },

    async setSubscribe () {
      // console.log("click subscribe")
      this.loadingSubscribe = true
      if (!this.settings.contact_in_team) {
        this.$http.post(`/projects/${this.context.identifier}/pingdom/notifications/`, {
          notification_targets: {
            email: [{
              severity: "LOW",
              address: this.settings.user.mail,
            }, {
              severity: "HIGH",
              address: this.settings.user.mail,
            }
          ],
        }
      })
        .then(response => {
          console.log(response.status)
          this.loadingSubscribe = false
          this.settings.contact_in_team = true
        })
        .catch(error => {
          console.log(error)
          throw error
        })
      } else {
        this.$http.delete(`/projects/${this.context.identifier}/pingdom/notifications/`)
        .then(response => {
          console.log(response.status)
          this.loadingSubscribe = false
          this.settings.contact_in_team = false
        })
        .catch(error => {
          console.log(error)
          throw error
        })
      }
    },
  },

  watch: {
    // à chaque fois que la question change, cette fonction s'exécutera
    context (newContext, oldContext) {
      if (newContext.identifier !== oldContext.identifier) {
        this.getChecks()
        this.getSettings()
      }
    }
  },
}
</script>
