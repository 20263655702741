import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    title: "Bearstech monitor",
    user: {"username": null, "is_staff": false},
    redmineHeaderOffset: 0,
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setUser (state, payload) {
      if (payload.username != state.user.username) {
          state.user = payload
          // console.log('User: ' + state.user.username)
      }
    },
    setTitle (state, payload) {
      if (!payload) { return }
      var title = payload + " - Bearstech monitor"
      if (title !== state.title) {
          state.title = title
      }
      if (document.title !== state.title) {
          document.title = state.title
      }
    },
    setRedmineHeaderOffset (state, payload) {
      state.redmineHeaderOffset = payload
    },
  }
})
