import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import axios from 'axios'
import VueMatomo from 'vue-matomo'

import router from './router'
import store from './store'

if (!/0.0.0.0/.exec(window.location.href)) {
    console.log('Enabling sentry')
    Sentry.init({
      dsn: 'https://c21acfeabced413d9e1725c47fb4642f:3ccb132bce5c4be285177e079b89ca6a@sentry.factory.sh/26',
      integrations: [new VueIntegration({ Vue, attachProps: true })],
    })
}

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Docs: https://github.com/AmazingDreams/vue-matomo
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://piwik.bearstech.com',
  siteId: 8,

  // Enables automatically registering pageviews on the router
  router: router,
})

Vue.prototype.$http = axios.create({
  baseURL: '/api/',
  timeout: 30000,
  headers: {'X-CSRFToken': Vue.$cookies.get('csrftoken')}
})


class ServersInfos{
    // cache infos used by <server-link />

    constructor(http) {
        this.$http = http
        this.cache = {}
    }
    get(server, infos) {
        if (!this.cache[server]) {
            if (infos["name"]) {
                // we have a valid infos object
                // console.log('Caching infos for ', server)
                this.cache[server] = new Promise((resolve) => {
                    resolve(infos)
                })
            } else {
                // get infos object via http
                // console.log('Caching http infos for ', server)
                this.cache[server] = new Promise((resolve) => {
                    this.$http.get(`/servers/${server}/infos`).then((data) => {
                      resolve(data.data.host)
                    })
                })
            }
        } else {
            // console.log('Using cached infos for ', server)
        }
        return this.cache[server]
    }
    reset() {
        this.cache = {}
    }
}


Vue.prototype.$servers_infos = new ServersInfos(Vue.prototype.$http)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
