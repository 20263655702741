<template>
  <b-card class="mb-4"
          v-if="series.length || latest_mails.length"
          >
    <b-card-title>
      <h-title label="Mails sortants" uid="mails"
               :isCardTitle="true"
               icon="envelope-open"
              ></h-title>
    </b-card-title>
    <div id="graphs">
        <div class="text-center">
          <div class="btn-group">
           <label class="btn btn-default">
             <input type="radio" value="day"
                    v-model="period" />
             Jour
           </label>
           <label class="btn btn-default">
             <input type="radio" value="week"
                    v-model="period" />
             Semaine
           </label>
           <label class="btn btn-default">
             <input type="radio" value="month"
                    v-model="period" />
             Mois
           </label>
          </div>
        </div>

        <mail-flow-graph
            :hostname="hostname"
            :series="series"
            :period="period"
            y_label="Nombre de mails"
            v-if="series.length"
            />

        <div v-if="latest_mails.length">
          <h6 class="h5 mb-0">20 derniers mails envoyés</h6>
          <b-table
            responsive
            hover
            :fields="latest_mails_fields"
            :items="latest_mails"
            >
            <template v-slot:cell(@timestamp)="data">
              {{ toLocaleDate(data.value) }}
            </template>
            <template v-slot:cell(postfix_to)="data">
              <div
                v-for="(item, i) in stringOrArrayValue(data.value)"
                :key="i"
                >{{ item }}</div>
            </template>
            <template v-slot:cell(postfix_status)="data">
              <div
                v-for="(item, i) in stringOrArrayValue(data.value)"
                :key="i"
                >{{ item }}</div>
            </template>
            <template v-slot:cell(postfix_status_message)="data">
              <div
                v-for="(item, i) in stringOrArrayValue(data.value || data.item.postfix_delivery_response )"
                :key="i"
                >{{ item }}</div>
            </template>
          </b-table>
        </div>

        <div v-if="rejected_from.length">
          <h6 class="h5 mb-0">Expéditeurs Invalides</h6>
          <b-table
            responsive
            hover
            :fields="['rejected_from', 'nombre_de_mails']"
            :items="rejected_from"
            >
            <template v-slot:cell(nombre_de_mails)="data">
              <div v-if="hostname">
                {{ data.value }}
                <a @click.prevent="showReason(hostname, 'rejected_from', data.item.rejected_from)"
                   href="#">
                  <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
                </a>
              </div>
              <div v-if="!hostname">
                <div v-for="(item, i) in data.item.hostnames" :key="i">
                  <server-link
                    :server="item.hostname"
                    :project_id="$route.params.project_id"
                    />:
                    {{ item.nombre_de_mails }}
                    <a @click.prevent="showReason(item.hostname, 'rejected_from', data.item.rejected_from)"
                       href="#">
                      <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
                    </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="bounced_to.length">
          <h6 class="h5 mb-0">Echecs d'envois</h6>
          <b-table
            responsive
            hover
            :fields="['bounced_to', 'nombre_de_mails']"
            :items="bounced_to"
            >
            <template v-slot:cell(nombre_de_mails)="data">
              <div v-if="hostname">
                {{ data.value }}
                <a @click.prevent="showReason(hostname, 'bounced_to', data.item.bounced_to)"
                   href="#">
                  <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
                </a>
              </div>
              <div v-if="!hostname">
                <div v-for="(item, i) in data.item.hostnames" :key="i">
                  <server-link
                    :server="item.hostname"
                    :project_id="$route.params.project_id"
                    />: {{ item.nombre_de_mails }}
                    <a @click.prevent="showReason(item.hostname, 'bounced_to', data.item.bounced_to)"
                       href="#">
                      <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
                    </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="from.length">
          <h6 class="h5 mb-0">Top Expéditeurs</h6>
          <b-table
            responsive
            hover
            :fields="['from', 'nombre_de_mails']"
            :items="from"
            >
            <template v-slot:cell(nombre_de_mails)="data">
              <div v-if="hostname">{{ data.value }}</div>
              <div v-if="!hostname">
                <div v-for="(item, i) in data.item.hostnames" :key="i">
                  <server-link
                    :server="item.hostname"
                    :project_id="$route.params.project_id"
                    />: {{ item.nombre_de_mails }}
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="to.length">
          <h6 class="h5 mb-0">Top destinataires</h6>
          <b-table
            responsive
            hover
            :fields="['to', 'nombre_de_mails']"
            :items="to"
            >
            <template v-slot:cell(nombre_de_mails)="data">
              <div v-if="hostname">{{ data.value }}</div>
              <div v-if="!hostname">
                <div v-for="(item, i) in data.item.hostnames" :key="i">
                  <server-link
                    :server="item.hostname"
                    :project_id="$route.params.project_id"
                    />: {{ item.nombre_de_mails }}
                </div>
              </div>
            </template>
          </b-table>
        </div>
    </div>
    <!-- modal -->
    <div>
      <b-modal id="reasons" hide-footer>
        <template #modal-title>
          Causes d'erreurs
          pour {{ reasons.mail }}
          sur {{ reasons.hostname }}
        </template>
        <ul>
          <li v-for="(item, i) in reasons.reasons" :key="i">
            {{ item }}
          </li>
        </ul>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
import MailFlowGraph from './MailFlowGraph.vue'
import ServerLink from '../misc/ServerLink.vue'
import HTitle from './HTitle.vue'

export default {
  components: {
    MailFlowGraph,
    ServerLink,
    HTitle,
  },
  props: {
    hostname: String,
    project_id: String,
  },
  data () {
    return {
      period: "day",
      series: [],
      rejected_from: [],
      bounced_to: [],
      to: [],
      from: [],
      reasons: {"hits": []},
      latest_mails: [],
      latest_mails_fields: [
        {key: "@timestamp", label: "Date"},
        {key: "postfix_from", label: "From"},
        {key: "postfix_to", label: "To"},
        {key: "postfix_status", label: "Status"},
        {key: "postfix_status_message", label: "Message"},
      ],
    }
  },
  mounted () {
    this.updateData()
  },
  watch: {
    "period": "updateData",
    "hostname": "updateData",
    "project_id": "updateData",
  },
  methods: {
    stringOrArrayValue (value) {
      if (typeof(value) == typeof([])) {
        return value
      }
      return [value]
    },
    toLocaleDate (d) {
      d = new Date(d)
      return d.toLocaleDateString() + " " + d.toLocaleTimeString()
    },
    showReason (hostname, field, mail) {
      var url = `/host_mails_reasons/${hostname}/${this.period}`
      url += `/${field}/${mail}`
      this.$http.get(url)
        .then((data) => {
          this.reasons = data.data
          this.$bvModal.show('reasons')
      })
    },
    updateData () {
      var url = ""
      if (this.hostname) {
        url = `/host_latest_mails/${this.hostname}`
        this.$http.get(url)
          .then((data) => {
            this.latest_mails = data.data
        })
        url = `/host_mails_datas/${this.hostname}/${this.period}`
      } else if (this.project_id) {
        url = `/project_mails_datas/${this.project_id}/${this.period}`
      }
      if (url) {
        this.$http.get(url)
          .then((data) => {
            for (var k in data.data) {
              this[k] = data.data[k]
            }
        })
      }
    },
  }
}

</script>
