<template>
    <b-table
      id="vhosts-table"
      responsive
      hover
      :fields="fields"
      :items="vhosts"
      :filter="filter"
      :filterIncludedFields="['host', 'servername']"
      >

      <template
        v-slot:thead-top="data"
        v-if="vhosts.length > 5"
        >
        <b-tr>
          <b-th :colspan="data.columns">
              <b-input
                placeholder="Rechercher un nom de serveur/domaine..."
                v-model="filter"
                />
          </b-th>
        </b-tr>
      </template>

      <template v-slot:cell(host)="data">
        <server-link
          :server="data.value"
          :project_id="$route.params.project_id"
          />
      </template>

      <template v-slot:cell(servername)="data">
        <b-link
          :href="(data.item.ssl ? 'https' : 'http') + '://' + data.value"
          >
          {{data.value}}:{{data.item.port}}
        </b-link>
      </template>

      <template v-slot:cell(specs)="data">
        <div v-if="data.item.root">
          <label>Root:</label> {{data.item.root}}
        </div>
        <div v-if="data.item.redirect">
          <label>Redirect:</label> {{data.item.redirect}}
        </div>
        <div v-if="data.item.wordpress">
          <b-row>
            <b-col
              :title="(data.item.wordpress.vuln || '') + ' ' + (data.item.wordpress.upgrade ? data.item.wordpress.upgrade.message : '')"
              >
              <b>
              Wordpress
              </b>
            <b-badge
              :class="[data.item.wordpress.upgrade ? data.item.wordpress.upgrade.css || 'bg-info': 'bg-info']"
              >
              {{data.item.wordpress.version}}
              <b-icon
                v-if="data.item.wordpress.vuln"
                icon="alert-circle-fill"
                variant="light"
                class="rounded-circle bg-danger">
              </b-icon>
            </b-badge>
            </b-col>
            <b-col cols="6"
              v-if="data.item.wordpress.theme.name"
              :title="'Version' + (data.item.wordpress.theme.version || 'unknown')"
              >
              <b-row>
                <b-col cols="8">
                  Theme {{data.item.wordpress.theme.name}}
                </b-col>
                <b-col cols="4">
                  <b-badge>
                    {{data.item.wordpress.theme.version || 'unknown'}}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6"
              v-for="(p, l) in data.item.wordpress.plugins"
              :key="l"
              >
              <b-row>
                <b-col cols="8" :class="{'text-warning': !p.infos}">
                  {{p.name}}
                </b-col>
                <b-col cols="4">
                  <b-badge
                    v-if="!p.infos"
                    variant="warning"
                    :title="(p.vuln || '') + '. Version courante inconnue.'"
                    >
                    {{p.version}}
                  </b-badge>
                  <b-badge
                     v-if="p.infos"
                     :variant="!p.vuln ? 'success' : (p.infos.version == p.version ? 'success' : 'danger')"
                     :title="(p.vuln || '') + ' Version courante:' + p.infos.version"
                     :href="p.infos ? 'http://wordpress.org/plugins/' + p.infos.slug : ''"
                     target="_new"
                     >
                    {{p.version}}
                    <b-icon
                      v-if="p.vuln"
                      icon="alert-circle-fill"
                      variant="light"
                      class="rounded-circle bg-danger"
                      ></b-icon>
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <div v-if="data.item.drupal">
          <b>
          Drupal
          </b>
          <b-badge variant="info">
            {{data.item.drupal.version}}
          </b-badge>
          <b-row v-for="(p, m) in data.item.drupal.modules" :key="m">
            <b-col cols="8">
              {{p.name}} - {{p.package}}
            </b-col>
            <b-col cols="4" class="text-left">
              <b-badge variant="info">
                {{p.version}}
              </b-badge>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-table>



</template>

<script>
import ServerLink from '../misc/ServerLink.vue'

export default {
  components: {
    ServerLink,
  },
  props: {
    vhosts: null,
  },
  data () {
    var fields = []
    if (!this.$route.params.server_id) {
        fields.push({key: "host", label: "Serveur",})
    }
    fields = fields.concat([
      {key: "servername", label: "Domain",},
      {key: "specs", label: "Spécifications",},
    ])
    return {
      filter: null,
      fields: fields,
    }
  },
}

</script>

<style>
  #vhosts-table a.badge {
    color: #fff !important;
  }
</style>
