<template>
  <h-titles>
    <h1 class="title h2">Mailing</h1>
     <b-alert
       :show="alert_type"
       :variant="alert_type"
       has-icon aria-close-label="Close message"
       dismissible>
       {{ alert }}
    </b-alert>
    <div v-show="!alert_type">

      <b-card class="mb-2">
        <div class="row">
          <div class="col-md">
            <b-form-group label="Container">
              <b-form-select
                multiple
                :select-size="8"
                v-model="containersModel"
                :options="containers">
              </b-form-select>
            </b-form-group>
            <div>
              <span>Selection:</span>
              <b-badge v-for="(item, i) in containersModel" :key="i">{{item}}</b-badge>
            </div>
          </div>

          <div class="col-md">
            <b-form-group label="Hosts">
              <b-form-select
                multiple
                :select-size="8"
                v-model="hostsModel"
                :options="hosts">
              </b-form-select>
            </b-form-group>
            <div>
              <span>Selection:</span>
              <b-badge v-for="(item, i) in hostsModel" :key="i">{{item}}</b-badge>
            </div>
          </div>
        </div>
      </b-card>

      <b-card>
        <div>
          <b>{{emails.length}} recipients</b> <b-button v-b-toggle.recipient-list variant="primary">Toggle recipient list</b-button>
          <b-collapse id="recipient-list" class="mt-2 mb-2">
            <b-table
              sort-icon-left
              responsive
              hover
              small
              foot-clone
              :items="emails"
              :fields="emailFields"
              >
            </b-table>
            <b-button v-b-toggle.recipient-list variant="primary">Close recipient list</b-button>
          </b-collapse>
        </div>

        <fieldset>
          <b-form-group label="Subject">
            <b-form-input
              v-model="subject"
              :state="subject.length >= 1"
              placeholder="Entrez le sujet du message"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Message" description="You can use the {hosts} variable in the message. This will be substituted by user's hosts (see table listing bellow)">
            <div class="control has-icon-right is-clearfix">
              <b-form-textarea
                style="width: 100%; min-height: 300px;"
                :class="'input ' + message_type"
                id="message"
                :state="message.length >= 1"
                placeholder="Entrez le corps du message"
                name="message" v-model="message"></b-form-textarea>
            </div>
          </b-form-group>

          <b-form-checkbox class="mb-2" v-model="test">
            Test (only send mail to yourself)
          </b-form-checkbox>

          <section>
            <b-button
              variant="primary"
              @click="submitForm"
            >Submit</b-button>
          </section>
        </fieldset>
      </b-card>

    </div> <!-- v-show="alert_type" -->

  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import jQuery from 'jquery'

export default {
  name: 'Mailing',
  components: {
    HTitles,
  },
  data () {
    this.$store.commit('setTitle', "Mailing")
    this.$store.commit('setLoading', true)
    return {
      alert_type: null,
      alert: null,

      subject: "",
      subject_type: "",
      message: "",
      message_type: "",

      containers: [],
      containersModel: [],
      hosts: [],
      hostsModel: [],

      emails: [],

      test: false,
      emailFields: [
        {key: "email", label: "Email", sortable: true},
        {key: "hosts", label: "Hosts", sortable: true},
      ]
    }
  },
  mounted () {
    this.$http.get(document.location.pathname.slice(1))
      .then((result) => {
        console.log(result)
        this.containers = result.data.containers;
        this.hosts = result.data.hosts;
        this.emails = result.data.emails;
        this.$store.commit('setLoading', false)
      })
      .catch((error) => {
        this.$store.commit('setLoading', false)
        throw error
      })
  },
  watch: {
    containersModel () {
      this.updateEmails()
    },
    hostsModel () {
      this.updateEmails()
    },
  },
  methods: {
    transformRequestAsFormPost (data) {
        // transform an object to form encoded data (django form)
        var name = null, value = null, buffer = [];
        // Serialize each key in the object.
        for (name in data) {
            if (data.hasOwnProperty(name)) {
                value = data[name];
                if (Array.isArray(value)) {
                    jQuery.each(value, function() {
                        buffer.push(
                            encodeURIComponent(name) + "=" +
                            encodeURIComponent((this === null) ? "" : this)
                        );
                    });
                } else {
                    buffer.push(
                        encodeURIComponent(name) + "=" +
                        encodeURIComponent((value === null) ? "" : value)
                    );
                }
            }
        }
        return buffer
            .join("&")
            .replace(/%20/g, "+")
    },
    updateEmails ()  {
      this.$store.commit('setLoading', true)
      this.emails = [];
      var data = {
            subject: '-',
            message: '-',
          }
      data.test = true
      if (this.containersModel.length) {
            data.containers = this.containersModel
      }
      if (this.hostsModel.length) {
            data.hosts = this.hostsModel
      }
      this.$http.request({
          url: "mailing/emails",
          method: 'post',
          data: data,
          headers: {'Content-Type': "application/x-www-form-urlencoded"},
          transformRequest: [this.transformRequestAsFormPost],

      }).then((result) => {
        console.log(result)
        this.emails = result.data.emails;
        this.$store.commit('setLoading', false)
      })
      .catch((error) => {
        this.alert_type = "is-danger"
        this.alert = error
        this.$store.commit('setLoading', false)
        throw error
      })
    },
    submitForm () {
      // check to make sure the form is completely valid
      if (!this.subject) {
        return
      }
      if (!this.message) {
        return
      }
      var data = {
            subject: this.subject,
            message: this.message,
            test: this.test,
          }
      if (this.containersModel.length) {
            data.containers = this.containersModel
      }
      if (this.hostsModel.length) {
            data.hosts = this.hostsModel
      }
      console.log(data)
      this.$http.request({
          url: "mailing",
          method: "post",
          data: data,
          headers: {'Content-Type': "application/x-www-form-urlencoded"},
          transformRequest: [this.transformRequestAsFormPost],

      }).then((result) => {
        console.log(result)
        var data = result.data;
        var type = data.success ? "alert-success" : "alert-danger",
            message = data.message,
            i = 0;
        if (data.success) {
          message += " Recipient list: ";
          for (i = 0; i<data.emails.length; i++) {
            message += data.emails[i]+", ";
          }
        }
        this.alert_type = type
        this.alert = message
        this.$store.commit('setLoading', false)
      })
      .catch((error) => {
        this.alert_type = "is-danger"
        this.alert = error
        this.$store.commit('setLoading', false)
        throw error
      })
    }
  }
}
</script>
