<template>
  <div v-if="!$store.state.loading">
    <h-titles v-if="certificates.length || acme_certificates.length ">
      <h1 class="title h2">Certificats expirés</h1>
      <b-card class="mb-4" no-body
        v-if="certificates.length"
        >
        <b-card-body>
          <h-title
            :label="certificates.length + ' certificats'"
            short_label="Certificats"
            uid="certificats"
            >
          </h-title>
          <p>
            Liste des certificats expirés
            ou expirant dans les 29 jours à venir
          </p>
        </b-card-body>
        <certificates-table :certificates="certificates" />
      </b-card>

      <b-card no-body
        v-if="acme_certificates.length"
        >
        <b-card-body>
          <h-title
            :label="acme_certificates.length + ' certificats Let\'s Encrypt'"
            short_label="Certificats LE"
            uid="acme_certificats"
            >
          </h-title>
          <p>
            Liste des certificats Let's Encrypt expirés
            ou expirant dans les 28 jours à venir (incorectement renouvelé par un
            cron)
          </p>
        </b-card-body>
        <certificates-table :certificates="acme_certificates" />
      </b-card>
    </h-titles>
  </div>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import HTitle from './misc/HTitle.vue'
import CertificatesTable from './tables/CertificatesTable.vue'

export default {
  name: 'Certificates',
  components: {
    HTitles,
    HTitle,
    CertificatesTable,
  },
  data () {
    return {
      certificates: [],
      acme_certificates: [],
      loading: true,
    }
  },
  mounted () {
    this.$store.commit('setTitle', "Certificats")
    this.$http.get(document.location.pathname.slice(1))
      .then((result) => {
        console.log(result)
        this.certificates = result.data.certificates
        this.acme_certificates = result.data.acme_certificates
        this.loading = false
      })
      .catch((error) => {
        this.data = []
        this.loading = false
        throw error
      })
  }
}
</script>
