<template>
  <h-titles>
    <h1 class="title h2">
      {{ count }} virtualhosts
    </h1>
    <b-form class="mb-2" inline>
      <label>Type</label>
      <span style="width:10px"></span>
      <b-form-select
        v-model="type"
        :options="types"
        @change="update"></b-form-select>
      <span style="width:30px"></span>
      <label>Criticité</label>
      <span style="width:10px"></span>
      <b-form-select
        v-model="criticity"
        :options="criticities"
        @change="update"></b-form-select>
    </b-form>
    <b-card no-body>
      <vhosts-table :vhosts="vhosts" />
    </b-card>
  </h-titles>
</template>

<script>
import HTitles from './misc/HTitles.vue'
import VhostsTable from './tables/VhostsTable.vue'

export default {
  components: {
    HTitles,
    VhostsTable,
  },
  data () {
    return {
      type: "wordpress",
      types: [
        {'value': 'wordpress', 'text': 'Wordpress'},
        {'value': 'drupal', 'text': 'Drupal'},
        {'value': 'all', 'text': 'Tous'},
      ],
      criticity: "production",
      criticities: [
        {'value': 'production', 'text': 'production'},
        {'value': 'development', 'text': 'development'},
        {'value': 'all', 'text': 'Toutes'},
      ],
      count: 0,
      vhosts: [],
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    update() {
      this.$store.commit('setTitle', 'Virtual hosts')
      this.$store.commit('setLoading', true)
      this.$http.get('vhosts?criticity=' + this.criticity + '&type=' + this.type)
        .then((result) => {
          this.count = result.data.count
          this.vhosts = result.data.vhosts
          this.$store.commit('setLoading', false)
        })
    },
  },
}

</script>
