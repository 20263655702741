<template>
  <div id="graphs">
      <div class="text-center">
        <div class="btn-group">
         <label class="btn btn-default">
           <input type="radio" value="day"
                  v-model="period" />
           Jour
         </label>
         <label class="btn btn-default">
           <input type="radio" value="week"
                  v-model="period" />
           Semaine
         </label>
         <label class="btn btn-default">
           <input type="radio" value="month"
                  v-model="period" />
           Mois
         </label>
         <label class="btn btn-default">
           <input type="radio" value="year"
                  v-model="period" />
           Année
         </label>
        </div>
      </div>
      <graph
           v-for="(p, i) in panels" :key="i"
           :hostname="hostname"
           :period="period"
           :panel_index="i"
           :panel="p"
           />
  </div>
</template>

<script>
import Graph from './Graph.vue'

export default {
  props: {
    hostname: String,
  },
  components: {
    Graph,
  },
  data () {
    return {
      period: "day",
      panels: [
        'CPU',
        'Load',
        'Memory',
        'Network 1',
        //'Network 2',
        'Disk IO',
        'Disk capacity'
      ],
    }
  },
}

</script>
